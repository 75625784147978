html, body {
    font-size: $base_fs;
    height: 100%;
    @include secondary-regular();
    &.no-scroll {
        overflow: hidden;
        position: relative;
        @include size-max(mobile) {
            -webkit-overflow-scrolling: touch;
        }
    }
}

main {
    @include size-max(tablet) {
        padding-top: 32px;
    }
}

html {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

#app {
    @include page-full-size(nav, main, footer);
}

.subtitle {
    max-width: 40rem;
    @include margin-center();
}

.mid-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.mid-item {
    text-align: center;
    &--evenly {
        flex-grow: 1;
        flex-basis: 0;
    }
    &--gutter {
        @include gutter();
    }
}

.bg-section {
    background-color: $background_c;
    &--secondary {
        background-color: $background_secondary_c;
    }
    &--inverse {
        background-color: $background_inverse_c;
    }
    &--extralarge {
        padding: 8rem 0;
    }
    &--large {
        padding: 6rem 0;
        @include size-max(tablet) {
            padding: 4rem 0;
        }
        @include size-max(mobile) {
            padding: 4rem 0;
        }
    }
    &--medium {
        padding: 4rem 0;
        @include size-max(mobile) {
            padding: 2.5rem 0;
        }
    }
    &--small {
        padding: 3rem 0;
        @include size-max(mobile) {
            padding: 2rem 0;
        }
    }
    &--extrasmall {
        padding: 2rem 0;
        @include size-max(mobile) {
            padding: 1rem 0;
        }
    }
    &--center {
        text-align: center;
    }
    &--smallbottom {
        padding-bottom: 3rem;
        @include size-max(mobile) {
            padding-bottom: 2rem;
        }
    }
    &--nopadding-bottom {
        padding-bottom: 0;
    }
}

.how_it_works_about {
    .bg-section--secondary {
        background-color: $background_c;
    }
}

.text-center {
    text-align: center;
}

.primary-color {
    color: $primary_action_c;
    @include transition((color));
    &__link {
        &:hover {
            color: $primary_hover_c;
        }
    }
}

.secondary-color {
    color: $secondary_action_c;
    @include transition((color));
    &__link {
        &:hover {
            color: $secondary_hover_c;
        }
    }
}

.small-text {
    font-size: 0.8rem;
    color: $text_c;
    line-height: 1.5;
}

.dot-div {
    @include size(4px);
    background-color: $text_c;
    border-radius: 50%;
    margin: 0 0.5rem;
}

.el-dialog {
    @include size-max(mobile) {
        width: 90% !important;
    }
}

.img-fallback {
    overflow: hidden;
    position: relative;
    font-size: 100px;
    &:after {
        content: '';
        background-color: white;
        background-image: url("/static/images/default.png");
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border: 0;
        height: 100%;
        width: 100%;
        background-size: 100% 100%;
        z-index: 2;
    }
}

[v-cloak] {
    display: none;
    .v-cloak {
        min-height: 40px;
        &--block {
            display: block !important;
        }
        &--inline {
            display: inline !important;
        }
        &--inlineBlock {
            display: inline-block !important;
            position: relative;
        }
        &--hidden {
            display: none !important;
        }
        &--invisible {
            visibility: hidden;
        }
    }
}

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none;
}

#CookiebotWidget{
    z-index: 13 !important;
}
