.contact-header {
  padding: 5rem 0 8rem;
  p {
    font-size: 1rem;
    margin: 0;
    padding-top: 0.5rem;
  }
  @include size-max(mobile) {
    padding: 2rem 0 4rem;
    h1 {
      font-size: 2.5rem;
    }
  }
}

.contact {
  @include size-max(mobile) {
      padding: 2.5rem 0;
  }
}

.contact-form {
  input, textarea {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    padding: 1.4rem 1.8rem;
    min-height: 4.5rem;
    @include placeholder() {
      @include secondary-regular();
      color: $text_c;
    }
    @include size-max(mobile) {
      margin-bottom: 1.2rem;
    }
  }
  button {
    margin: 0.7rem 0;
  }

}

.contact-info {
  p {
    margin: 0;
    >b, >strong {
      @include secondary-medium();
    }
  }
  a, p>a {
    @include primary-bold-italic();
    color: $headline_c;
    font-size: 1.2rem;
    padding: 0.5rem 0;
    display: block;
    text-transform: uppercase;
    text-decoration: unset;
    &:hover {
      color: $secondary_action_c;
    }
  }
  h6 {
    @include secondary-medium();
    font-style: unset;
    color: $headline_c;
    text-transform: unset;
    font-size: 1.2rem;
    margin: 0;
  }
  @include size-max(mobile) {
    padding-top: 4rem;
    &__text {
      text-align: center;
    }
    a {
      color: $primary_action_c !important;
      &:hover {
        color: $primary_hover_c;
      }
    }
  }
}