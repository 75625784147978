.image-item {
  text-align: center;
  padding-bottom: 0.5rem;
  @include gutter();
  >img {
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 10rem;
    @include margin-center();
  }
  &__title {
    font-size: 1rem;
    @include size-max(mobile) {
      font-size: 0.9rem;
      margin: 0.5rem 0 0 0;
    }
  }
}

.images-bottom-container {
  .image-item {
    flex: 1;
    @include size-max(tablet) {
      flex: 1 25%;
    }
  }
}

.image-item-col {
  .image-item {
    @include size-max(mobile) {
      padding: 0;
    }
  }
}