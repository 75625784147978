.button {
    display: inline-block;
    height: $button_height_h;
    line-height: $button_height_h - 4;
    font-size: 17px;
    padding: 0 3rem;
    border-radius: $button_radius;
    border: 2px solid transparent;
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
    font-stretch: condensed;
    font-style: normal;
    color: $text_inverse_c;
    background-color: $primary_action_c;
    @include transition((color, background-color));
    @include primary-bold();
    position: relative;
    &:hover {
        background-color: $primary_hover_c;
    }
    &--medium {
        height: $button_medium_height_h;
        line-height: $button_medium_height_h - 4;
        padding: 0 2rem;
        border-radius: 4px;
    }
    &--small {
        height: $button_small_height_h;
        line-height: $button_small_height_h - 4;
        padding: 0 1rem;
        border-radius: 4px;
    }
    &--secondary {
        background-color: $secondary_action_c;
        &:hover {
            background-color: $secondary_hover_c;
        }
    }
    &--inverse {
        background-color: $background_c;
        border-color: $primary_action_c;
        color: $primary_action_c;
        border-width: 2px;
        &:hover {
            background-color: $primary_action_c;
            color: $text_inverse_c;
        }
    }
    &--inverse-secondary {
        background-color: $background_c;
        color: $secondary_action_c;
        border-color: $secondary_action_c;
        border-width: 2px;
        &:hover, &-hover {
            background-color: $secondary_action_c;
            color: $text_inverse_c;
        }
    }
    &--inverse-hover {
        &:hover {
            background-color: $background_c;
            color: $primary_action_c;
        }
    }
    &--full-width {
        width: 100%;
    }
    &--transparent-hover {
        border: 1px solid $primary_action_c;
        border-radius: $button_radius;
        &:hover {
            background-color: transparent;
            color: $primary_action_c;
        }
    }
    &--margin-top {
        margin-top: 2rem;
    }
    &:focus {
        outline: none;
    }
    &--show {
        text-align: left;
        padding: 0 1rem;
        @include primary-bold-italic();
        font-size: 1.2rem;
    }
    .button-icon {
        position: absolute;
        right: 1rem;
        display: block;
        @include absolute-center($direction: vertical);
        opacity: 0;
        @include transition((opacity));
        &.show {
            opacity: 1;
        }
    }
    &--slow {
        margin-right: 2px;
        &.active {
            color: $secondary_action_c;
        }
    }
    &--loop {
        &.active {
            color: $secondary_action_c;
        }
    }
    &--facebook {
        background-color: #1877F2 !important;
        color: white;
        margin-top: 10px;
    }
    &--google {
        background-color: white !important;
        box-shadow: 0 2px 3px rgba(0,0,0,0.168), 0 0 3px rgba(0,0,0,0.084);
        color: rgba(0,0,0,0.54);
        margin-top: 10px;

    }
    &--apple {
        background-color: black !important;
        color: white;
        margin-top: 10px;

    }
    &--full {
        width: 100%;
    }
    &--facebook, &--google, &--apple{
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            margin-right: 8px;

        }
    }
    &--rectangle {
        border-radius: $button-radius;
        text-transform: uppercase;
        background-color: transparent;
        color: $text_c;
        i {
            font-size: 10px;
            vertical-align: middle;
        }
        .sb-icon-arrow-left {
            padding-right: 10px;
            position: relative;
        }
        .sb-icon-arrow-right {
            padding-left: 10px;
            position: relative;
        }
    }
    &--donate {
        width: 100%;
        margin-top: 5px;
    }
}
.add-button {
    @include reset-button();
    @include border();
    border-radius: $button_radius;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    @include secondary-medium();
    color: $text_c;
    &__icon {
        @include size(12px);
        @include border();
        position: relative;
        border-radius: 50%;
        margin-right: 0.5rem;
        > i {
            @include absolute-center();
            font-size: 0.5rem;
        }
    }
}