.match-results {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  &__team {
    display: flex;
    align-items: center;
    flex: 2;
    @include truncated-text($flex: 2, $child: '.match-results__team-name');
    img {
      max-height: 2.7rem;
      max-width: 2.7rem;
      width: auto;
      height: auto;
    }
    &--home {
      justify-content: flex-end;
      img {
        margin: 0 0.5rem 0 1.5rem;
      }
    }
    &--away {
      justify-content: flex-start;
      img {
        margin: 0 1.5rem 0 0.5rem;
      }
    }
    @include size-max(tablet) {
      img {
        margin: 0 0.5rem;
      }
    }
  }
  &__team-name {
    @include primary-bold-italic();
    font-size: 1.2rem;
    color: $headline_c;
    text-transform: uppercase;
  }
  &__state {
    @include primary-bold();
    font-size: 1.8rem;
    color: $headline_c;
    flex: 1;
    justify-content: center;
    >span {
      padding: 0 0.5rem;
    }
    display: flex;
    align-items: center;
  }
  &--big {
    padding: 2rem 0;
    @include border($pos: bottom);
    .match-results__team-name {
      font-size: 1.8rem;
    }
    .match-results__state {
      font-size: 2rem;
    }
  }
  @include size-max(tablet) {
    @include border($pos: bottom);
    padding: 1rem;
    justify-content: space-between;
    &__team-name {
      margin-bottom: 1.2rem;
    }
  }
}