@import '../font-awesome/fontawesome';
@include declare-font-face('Material Icons', '../fonts/MaterialIcons', 'MaterialIcons-Regular', 400);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@include declare-font-face('icomoon-sportvideo', '../fonts', 'icomoon-sportvideo');

[class^="sport-"], [class*=" sport-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-sportvideo' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sport-sv_icon-fieldhockey:before {
  content: "\e9b2";
}
.sport-sv_icon-floorball:before {
  content: "\e900";
}
.sport-sv_icon-inlinehockey:before {
  content: "\e901";
}
.sport-sv_icon-futsal:before {
  content: "\e902";
}
.sport-sv_icon-mma:before {
  content: "\e903";
}
.sport-sv_icon-hockeyball:before {
  content: "\e904";
}
.sport-sv_icon-waterpolo:before {
  content: "\e905";
}
.sport-chevron-circle-right:before {
  content: "\e906";
}
.sport-icons8-base_jumping:before {
  content: "\e907";
}
.sport-icons8-baseball:before {
  content: "\e908";
}
.sport-icons8-basketball:before {
  content: "\e909";
}
.sport-icons8-basketball_field:before {
  content: "\e90a";
}
.sport-icons8-basketball_jersey:before {
  content: "\e90b";
}
.sport-icons8-beach_ball:before {
  content: "\e90c";
}
.sport-icons8-belayer:before {
  content: "\e90d";
}
.sport-icons8-bench_over_head:before {
  content: "\e90e";
}
.sport-icons8-bench_press:before {
  content: "\e90f";
}
.sport-icons8-bench_press_with_dumbbells:before {
  content: "\e910";
}
.sport-icons8-bmx:before {
  content: "\e911";
}
.sport-icons8-bodyboard:before {
  content: "\e912";
}
.sport-icons8-bowling:before {
  content: "\e913";
}
.sport-icons8-boxing:before {
  content: "\e914";
}
.sport-icons8-bungee_jumping:before {
  content: "\e915";
}
.sport-icons8-carabiner:before {
  content: "\e916";
}
.sport-icons8-centre_of_gravity:before {
  content: "\e917";
}
.sport-icons8-chalk_bag:before {
  content: "\e918";
}
.sport-icons8-championship_belt:before {
  content: "\e919";
}
.sport-icons8-chest:before {
  content: "\e91a";
}
.sport-icons8-climbing:before {
  content: "\e91b";
}
.sport-icons8-climbing_anchor:before {
  content: "\e91c";
}
.sport-icons8-climbing_helmet:before {
  content: "\e91d";
}
.sport-icons8-climbing_shoes:before {
  content: "\e91e";
}
.sport-icons8-coach:before {
  content: "\e91f";
}
.sport-icons8-cricket:before {
  content: "\e920";
}
.sport-icons8-cricketer:before {
  content: "\e921";
}
.sport-icons8-curls_with_dumbbells:before {
  content: "\e922";
}
.sport-icons8-dancing:before {
  content: "\e923";
}
.sport-icons8-discus_throw:before {
  content: "\e924";
}
.sport-icons8-drop_zone:before {
  content: "\e925";
}
.sport-icons8-dumbbell:before {
  content: "\e926";
}
.sport-icons8-energy_absorber:before {
  content: "\e927";
}
.sport-icons8-exercise:before {
  content: "\e928";
}
.sport-icons8-finish_flag:before {
  content: "\e929";
}
.sport-icons8-fishing:before {
  content: "\e92a";
}
.sport-icons8-flex_biceps:before {
  content: "\e92b";
}
.sport-icons8-floating_guru:before {
  content: "\e92c";
}
.sport-icons8-football:before {
  content: "\e92d";
}
.sport-icons8-football2:before {
  content: "\e92e";
}
.sport-icons8-foul:before {
  content: "\e92f";
}
.sport-icons8-frisbee:before {
  content: "\e930";
}
.sport-icons8-golf:before {
  content: "\e931";
}
.sport-icons8-golf_bag:before {
  content: "\e932";
}
.sport-icons8-golf_ball:before {
  content: "\e933";
}
.sport-icons8-guru:before {
  content: "\e934";
}
.sport-icons8-gymnastics:before {
  content: "\e935";
}
.sport-icons8-hammer_throw:before {
  content: "\e936";
}
.sport-icons8-handball:before {
  content: "\e937";
}
.sport-icons8-harness:before {
  content: "\e938";
}
.sport-icons8-high_jump:before {
  content: "\e939";
}
.sport-icons8-hockey:before {
  content: "\e93a";
}
.sport-icons8-horseback_riding:before {
  content: "\e93b";
}
.sport-icons8-in_air:before {
  content: "\e93c";
}
.sport-icons8-in_sea:before {
  content: "\e93d";
}
.sport-icons8-javelin_throw:before {
  content: "\e93e";
}
.sport-icons8-jump_rope:before {
  content: "\e93f";
}
.sport-icons8-kayaking:before {
  content: "\e940";
}
.sport-icons8-kitesurfing:before {
  content: "\e941";
}
.sport-icons8-lacrosse_stick:before {
  content: "\e942";
}
.sport-icons8-leaderboard:before {
  content: "\e943";
}
.sport-icons8-leg:before {
  content: "\e944";
}
.sport-icons8-long_jump:before {
  content: "\e945";
}
.sport-icons8-medal2:before {
  content: "\e946";
}
.sport-icons8-medal2_second_place:before {
  content: "\e947";
}
.sport-icons8-medal2_third_place:before {
  content: "\e948";
}
.sport-icons8-meditation_guru:before {
  content: "\e949";
}
.sport-icons8-motocross:before {
  content: "\e94a";
}
.sport-icons8-mountain_biking:before {
  content: "\e94b";
}
.sport-icons8-neck:before {
  content: "\e94c";
}
.sport-icons8-olympic_medal:before {
  content: "\e94d";
}
.sport-icons8-olympic_medal_bronze:before {
  content: "\e94e";
}
.sport-icons8-olympic_medal_silver:before {
  content: "\e94f";
}
.sport-icons8-olympic_rings:before {
  content: "\e950";
}
.sport-icons8-olympic_torch:before {
  content: "\e951";
}
.sport-icons8-paddling:before {
  content: "\e952";
}
.sport-icons8-parachute:before {
  content: "\e953";
}
.sport-icons8-paragliding:before {
  content: "\e954";
}
.sport-icons8-parkour:before {
  content: "\e955";
}
.sport-icons8-pilates:before {
  content: "\e956";
}
.sport-icons8-ping_pong:before {
  content: "\e957";
}
.sport-icons8-player_change:before {
  content: "\e958";
}
.sport-icons8-player_shirt:before {
  content: "\e959";
}
.sport-icons8-pole_vault:before {
  content: "\e95a";
}
.sport-icons8-prelum:before {
  content: "\e95b";
}
.sport-icons8-pulley:before {
  content: "\e95c";
}
.sport-icons8-pullups:before {
  content: "\e95d";
}
.sport-icons8-pushups:before {
  content: "\e95e";
}
.sport-icons8-quickdraw:before {
  content: "\e95f";
}
.sport-icons8-ramp:before {
  content: "\e960";
}
.sport-icons8-referee:before {
  content: "\e961";
}
.sport-icons8-referee_jersey:before {
  content: "\e962";
}
.sport-icons8-regular_biking:before {
  content: "\e963";
}
.sport-icons8-rod_barbells:before {
  content: "\e964";
}
.sport-icons8-roller_skating:before {
  content: "\e965";
}
.sport-icons8-rollerblade:before {
  content: "\e966";
}
.sport-icons8-rope:before {
  content: "\e967";
}
.sport-icons8-rowing:before {
  content: "\e968";
}
.sport-icons8-rugby:before {
  content: "\e969";
}
.sport-icons8-running:before {
  content: "\e96a";
}
.sport-icons8-shot_put:before {
  content: "\e96b";
}
.sport-icons8-shoulders:before {
  content: "\e96c";
}
.sport-icons8-shunt:before {
  content: "\e96d";
}
.sport-icons8-shuttercock:before {
  content: "\e96e";
}
.sport-icons8-skateboard:before {
  content: "\e96f";
}
.sport-icons8-skateboard_grip_tape:before {
  content: "\e970";
}
.sport-icons8-skateboard_truck:before {
  content: "\e971";
}
.sport-icons8-skateboard_wheel:before {
  content: "\e972";
}
.sport-icons8-skateboard_without_wheels:before {
  content: "\e973";
}
.sport-icons8-skateboarding:before {
  content: "\e974";
}
.sport-icons8-skatepark:before {
  content: "\e975";
}
.sport-icons8-ski_mask:before {
  content: "\e976";
}
.sport-icons8-ski_simulator:before {
  content: "\e977";
}
.sport-icons8-skiing:before {
  content: "\e978";
}
.sport-icons8-skydiving:before {
  content: "\e979";
}
.sport-icons8-skydiving_gear:before {
  content: "\e97a";
}
.sport-icons8-snowboarding:before {
  content: "\e97b";
}
.sport-icons8-soccer_corner:before {
  content: "\e97c";
}
.sport-icons8-soccer_goal:before {
  content: "\e97d";
}
.sport-icons8-soccer_yellow_card:before {
  content: "\e97e";
}
.sport-icons8-sparring:before {
  content: "\e97f";
}
.sport-icons8-sport:before {
  content: "\e980";
}
.sport-icons8-sport_net:before {
  content: "\e981";
}
.sport-icons8-sporty_wheelchair_user:before {
  content: "\e982";
}
.sport-icons8-square_parachute:before {
  content: "\e983";
}
.sport-icons8-squats:before {
  content: "\e984";
}
.sport-icons8-stadium:before {
  content: "\e985";
}
.sport-icons8-stepper:before {
  content: "\e986";
}
.sport-icons8-stick_fighting:before {
  content: "\e987";
}
.sport-icons8-strategy_board:before {
  content: "\e988";
}
.sport-icons8-sup:before {
  content: "\e989";
}
.sport-icons8-surfing:before {
  content: "\e98a";
}
.sport-icons8-swimmer_back_view:before {
  content: "\e98b";
}
.sport-icons8-swimming:before {
  content: "\e98c";
}
.sport-icons8-swimming_back_view:before {
  content: "\e98d";
}
.sport-icons8-swivel:before {
  content: "\e98e";
}
.sport-icons8-teams:before {
  content: "\e98f";
}
.sport-icons8-tennis:before {
  content: "\e990";
}
.sport-icons8-time_trial_biking:before {
  content: "\e991";
}
.sport-icons8-torso:before {
  content: "\e992";
}
.sport-icons8-trainers:before {
  content: "\e993";
}
.sport-icons8-trampoline_park:before {
  content: "\e994";
}
.sport-icons8-treadmill:before {
  content: "\e995";
}
.sport-icons8-trekking:before {
  content: "\e996";
}
.sport-icons8-tricycle:before {
  content: "\e997";
}
.sport-icons8-trophy:before {
  content: "\e998";
}
.sport-icons8-uefa_euro_trophy:before {
  content: "\e999";
}
.sport-icons8-volleyball:before {
  content: "\e99a";
}
.sport-icons8-wakeboarding:before {
  content: "\e99b";
}
.sport-icons8-wakeup_hill_on_stairs:before {
  content: "\e99c";
}
.sport-icons8-walking:before {
  content: "\e99d";
}
.sport-icons8-weightlift:before {
  content: "\e99e";
}
.sport-icons8-whistle:before {
  content: "\e99f";
}
.sport-icons8-world_cup:before {
  content: "\e9a0";
}
.sport-icons8-wrestling:before {
  content: "\e9a1";
}
.sport-icons8-yoga:before {
  content: "\e9a2";
}
.sport-icons8-acrobatics:before {
  content: "\e9a3";
}
.sport-icons8-archers_arrow:before {
  content: "\e9a4";
}
.sport-icons8-archers_bow:before {
  content: "\e9a5";
}
.sport-icons8-ascender:before {
  content: "\e9a6";
}
.sport-icons8-back_muscles:before {
  content: "\e9a7";
}
.sport-icons8-badminton:before {
  content: "\e9a8";
}
.sport-icons8-badminton_racquet:before {
  content: "\e9a9";
}
.sport-icons8-barbell:before {
  content: "\e9aa";
}
.sport-logo-sportvideo:before {
  content: "\e9ab";
}
.sport-play-button:before {
  content: "\e9ac";
}
.sport-icons-8-trophy:before {
  content: "\e9ad";
}
.sport-icons-8-help:before {
  content: "\e9ae";
}
.sport-thank:before {
  content: "\e9af";
}
.sport-icons-8-idea:before {
  content: "\e9b0";
}
.sport-close-menu:before {
  content: "\e9b1";
}