.created-by {
    padding-top: 15px;

    &--logo {
        transform: translate3d(0, 0, 0);
        display: inline-block;
        background-repeat: no-repeat;
        height: 8px;
        width: 109px;
        background-position: 0 0 !important;
        margin-left: 3px;
        transition: all .2s ease-in-out;

        &:hover {
            background-position: 0 -8px !important;
        }
    }

    @media all and (min-width: $break_xs_sm) {
        text-align: right;
        padding-top: 0;
    }
}

.footer {
    border-top: 1px solid $dividing_c;

    &__subtitle {
        font-size: 0.85rem;
        color: $text_c;
        margin: 2.5rem 0 0.5rem;
        @include secondary-bold();
    }

    &__navbar {
        border-top: 1px solid $dividing_c;
        padding-top: 2rem;
        margin-top: 2rem;
        @include size-max(tablet) {
            text-align: center;
        }
        @include size-max(mobile) {
            border-top: none;
            padding-top: 0;
            margin: 1rem 0;
        }
    }

    &__navbar-list {
        padding: 0;
        margin: 0;
        display: flex;
        max-width: 100%;
        align-items: center;
        flex-wrap: wrap;

        > li {
            display: inline-flex;
            flex: 1 1 100%;
            flex-basis: auto;
            padding: 10px;
            width: 100%;
            @include size-min(tablet) {
                justify-content: center;
                padding: 0;
                width: auto;
            }
        }

        @include size-max(laptop) {
            padding: 0 -1rem;
        }
    }

    &__socials {
        margin-top: 1.8rem;
        a {
            margin-right: 1rem;
            color: $text_c;
            font-size: 1.4rem;
            path {
                -webkit-transition: fill .2s ease-in-out;
                -moz-transition: fill .8s ease;
                -ms-transition: fill .8s ease;
                -o-transition: fill .8s ease;
                transition: fill .8s ease;
            }
            svg:hover > path{
                fill: $secondary_action_c;
            }
            &:hover {
                color: $secondary_action_c;
            }
        }
    }

    @include size-max(tablet) {
        &__apps {
            margin: 1.8rem 0;
        }
    }

    @include size-max(mobile) {
        &__subtitle {
            margin-top: 0;
        }
        &__select {
            width: 100%;
        }
        &__apps {
            margin: 1.8rem 0;
        }
        padding-bottom: 0;
        &__apps-wrapper {
            display: flex;
            justify-content: center;

            > a {
                &:last-child {
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

.footer-menu {
    &__logo {
        max-height: 2rem;
    }

    &__header {
        font-size: 1rem;
        @include primary-bold();
        color: $secondary_action_c;
        padding-bottom: 1rem;
        text-transform: uppercase;

        &--collapsing {
            border-bottom: 1px solid $dividing_c;
        }
    }

    &__list {
        @include reset-list();
    }

    &__item {
        @include reset-list();
        padding: 0.5rem 0;
        @include secondary-regular();

        a {
            color: $text_c;
            font-size: 1rem;

            &:hover {
                color: $secondary_action_c;
            }
        }
    }

    &__link {
        padding: 1.5rem 0;

        > a {
            font-size: 1rem;
        }
    }

    &__app {
        > img {
            max-height: 2.5rem;
            display: inline;
        }
    }

    @include size-max(mobile) {
        margin-bottom: 2rem;
        &__app {
            display: inline-block;
            width: calc(50% - 15px);
            max-width: 10rem;
            margin-right: 15px;

            > img {
                width: 100%;
                height: 100%;
                max-height: 2.5rem;
            }
        }
    }
}

.footer-menu-xs {
    .el-collapse-item__header {
        background-color: $background_secondary_c;
        color: $secondary_action_c;
        text-transform: uppercase;
        font-size: 1rem;
        @include primary-bold();
        padding: 2rem 1rem;
        line-height: 1;
        height: auto;

        > i {
            line-height: 1;
        }
    }

    .el-collapse-item__content {
        background-color: $background_secondary_c;
        padding: 0 2rem 1rem;
    }
}