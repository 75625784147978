.select {
  span {
    color: $text_c;
  }
  input {
    color: $text_c;
    border: none;
    @include placeholder() {
      color: $text_c;
      @include secondary-regular();
    }
    &.el-input__inner {
      background-color: $background_secondary_c;
    }
    &.el-select__input {
      margin-left: 32px;
    }
  }
  &--primary {
    input {
       &.el-input__inner {
        background-color: $background_c;
      }
    }
  }
}

.text-select {
  display: inline-block;
  @include fa-icon();
  .el-input__inner {
    border: none;
    background: transparent;
    padding: 0 3rem 0 0;
    vertical-align: sub;
    color: $secondary_action_c;
    @include primary-bold-italic();
    font-size: 1.8rem;
    text-transform: uppercase;
    min-width: 16rem;
    max-width: 42rem;
    @include placeholder() {
      color: $secondary_action_c;
      @include primary-bold-italic();
      font-size: 1.8rem;
      text-transform: uppercase;
    }
    @include size-max(tablet) {
      font-size: 1.5rem;
      min-width: 14rem;
      @include placeholder() {
        font-size: 1.5rem;
      }
    }
  }
}