.cta-tile {
  @include bg();
  background-color: $background_inverse_c;
  h2 {
    color: $headline_inverse_c;
    text-align: center;
    padding-bottom: 1rem;
    position: relative;
    @include after() {
      background-color: $secondary_action_c;
      width: 32px;
      height: 4px;
      bottom: 0;
      @include absolute-center($direction: horizontal);
    }
  }
  &__description {
    @include margin-center();
    color: $text_inverse_c;
    p {
      font-size: 1rem;
      line-height: 1.8rem;
      color: $text_inverse_c;
    }
  }
  &__apps {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    >a {
      @include gutter();
      >img {
        max-height: 3rem;
      }
      @include size-max(mobile) {
        >img {
          max-height: 2.5rem;
        }
        padding: 0 0.5rem;
      }
    }
  }
  &__button {
    padding: 1rem 0;
  }
}