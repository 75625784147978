.partners {
  text-align: center;
  &__item {
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 14rem;
    img {
      max-width: 100%;
      max-height: 16rem;
      width: auto;
      height: auto;
    }
    &:focus {
      outline: none;
    }
  }
  &__slider {
    margin-top: 4rem;
    .slider-arrow {
      &--prev {
        left: -6%;
      }
      &--next {
        right: -6%;
      }
    }
    .slick-initialized .slick-slide {
      display: inline-block;
      vertical-align: middle;
      float: none;
    }
    @include size-max(mobile) {
      margin-top: 2rem;
    }
  }
}