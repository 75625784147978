.main-filter-wrapper {
  padding: 8rem 0 4rem;
  @include margin-center();
  .title {
    text-align: center;
    >span {
      display: block;
    }
  }
  @include size-max(tablet) {
    padding: 4rem 0;
  }
  @include size-max(mobile) {
    padding: 2rem 0;
  }
}