.slider-arrow {
  @include reset-button();
  @include size($slider_button_w);
  background-color: $slider_button_background_c;
  border-radius: 50%;
  box-shadow: 0 16px 32px 0 $shadow_c;
  position: absolute;
  @include absolute-center($direction: vertical);
  @include transition((background-color));
  >i {
    color: $slider_button_c;
    @include transition((color));
  }
  &--prev {
    z-index: 2;
  }
  &:hover {
    &:not(.slick-disabled) {
      background-color: $slider_button_c;
      > i {
        color: $slider_button_background_c;
      }
    }
  }
  &.slick-disabled {
    background-color: $disabled_c;
    >i {
      color: $text_inverse_c
    }
    &:hover {
      cursor: auto;
    }
  }
}

.slick-dots {
  padding: 0;
  margin: 1rem 0 0 0;
  text-align: center;
  >li {
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;
    @include transition((border-color));
    position: relative;
    @include before() {
      @include absolute-center();
      @include size(0);
      opacity: 0;
      border: 1px solid $secondary_action_c;
      border-radius: 50%;
      @include transition((opacity));
      top: calc(50% - 1px);
    }
    &.slick-active {
      &:before {
        opacity: 1;
        @include size(16px);
      }
    }
    >button {
      @include size(8px);
      background-color: $secondary_action_c;
      border: none;
      padding: 0;
      border-radius: 50%;
      margin: 0.5rem;
      color: transparent;
      outline: none;
    }
  }
}

.primary-slick-dots {
  @extend .slick-dots;
    >li {
      &:before {
        border-color: $primary_action_c;
      }
      >button {
        background-color: $primary_action_c;
      }
    }
}