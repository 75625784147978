.rectangle {
  height: 70px;
  padding: 24px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.3);

  p{
    margin: auto;
    line-height: normal;

    strong{
      color: #121212;
    }
  }
}

.rectangle-grey {
  height: auto;
  padding: 20px;
  margin: auto;
  border-radius: 8px;
  border: solid 1px rgba(255, 255, 255, 0.4);
  background-color: #eeeeee;

  p{
    margin: auto;
    line-height: normal;

    strong{
      color: #121212;
    }
  }
}

.bold {
  font-weight: bold;
}

.text-black {
  color: #121212;
}

.new-button {
  display: block;
  margin: auto;
  height: 50px;
  border-radius: $button_radius;
  background-color: $primary_action_c;
  font-size: 17px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.pink-link {
  color: #fa1f63;
  text-decoration: none;
}

.capital{
  text-transform: capitalize;
}

.new-registration__form .el-select .el-input__inner:focus {
    border-color: rgba(0, 0, 0, 0.3) !important;
}

.new-registration__form .el-select .el-input.is-focus .el-input__inner {
  &:focus{
    border-color: rgba(0, 0, 0, 0.3) !important;
  }
}

@media (max-width: 575px){
  .row-name{
    padding-left: 15px;
    padding-right: 15px;
  }
}

.left .logo {
  flex: 0.05;
  margin-left: 30px;
  margin-top: 30px;
}

.new-registration__form .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #fa1f63;
  border-color: #fa1f63;
}

.new-registration__form .el-checkbox__input .el-checkbox__inner:hover {
  border-color: #fa1f63;
}

.new-registration__form .el-checkbox__input .el-checkbox__inner{
  border-color: #fa1f63;
}

.new-registration__form .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
}