.statistics {
  &__description {
    p {
      margin-top: 0;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6rem;
  }
  &__item {
    flex: 1 0 20%;
    text-align: center;
    @include gutter();
    margin: 1rem 0;
  }
  &__item-title {
    color: $text_c;
    font-size: 1rem;
  }
  &__data {
    color: $secondary_action_c;
    font-size: 4rem;
    @include primary-bold-italic();
  }
  @include size-max(tablet) {
    &__row {
      margin-top: 3rem;
    }
    &__data {
      font-size: 2.5rem;
    }
  }
  @include size-max(mobile) {
    &__description {
      p {
        font-size: 1.2rem;
      }
    }
    h3 {
      font-size: 1.8rem;
    }
  }
}