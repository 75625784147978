.subscription-step {
  padding: 3rem 0;
  min-height: calc(100vh - 154px);
  display: flex;
  align-items: center;
  &__remove{
    background: none;
    border: 0;
    i{
      color: $primary_action_c;
    }
  }
  h2 {
    font-size: 2.5rem;
    >span {
      color: $primary_action_c;
    }
    @include size-max(mobile) {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }
  h5 {
    margin-top: 2rem;
  }
  h6 {
    color: $text_c;
    margin: 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    @include secondary-regular();
  }
  &__num {
    text-transform: uppercase;
    @include secondary-regular();
    margin-bottom: 0.5rem;
    >b, >strong {
      @include secondary-bold();
    }
  }
  .link {
    margin-top: 1.8rem;
    @include flex-center();
    width: 100%;
    >i {
      transform: rotate(180deg);
      margin-right: 0.5rem;
      display: block;
    }
  }
  &__small-title {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: $text_c;
    margin: 1.8rem 0 0.5rem;
  }
  .small-text {
    margin: 0.5rem 0;
  }
  .add-button {
    margin-top: 1.5rem;
  }
  &__file-row {
    display: flex;
    margin-bottom: 0.5rem;
  }
  &__form {
    margin: 2rem 0;
    .el-input__inner, .el-textarea__inner {
      color: $text_c;
      height: $input_h;
      line-height: $input_h;
      border: 1px solid $dividing_c;
      padding: 0 1.4rem;
      @include placeholder() {
        color: $text_c;
      }
    }
    .el-textarea__inner {
      min-height: 10rem !important;
    }
    .input-with-select {
      .el-select {
        min-width: 8rem;
      }
      .el-input-group__prepend {
        background-color: $background_c;
        border-color: $dividing_c;
      }
    }
  }
  &__tooltip-row {
    display: flex;
    align-items: center;
    >i {
      margin-left: 0.5rem;
      color: $text_c;
    }
  }
  &__description {
    >b, >strong {
      color: $headline_c;
    }
    &--red{
      color: $primary_action_c;
    }
  }
  &__thanks {
    >i {
      color: $primary_action_c;
      font-size: 5rem;
      margin-bottom: 1rem;
      display: block;
    }
    >h6 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }
  &__phone-number {
    margin: 1rem 0 0;
  }
}

.subscription-upload-wrapper {
  height: $input_h;
  line-height: $input_h;
  position: relative;
  width: 100%;
  >div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .el-input {
    z-index: 3;
    padding: 0;
  }
  #subscription-upload {
    opacity: 0;
    padding: 0;
    cursor: pointer;
  }
  &__error {
    .subscription-upload button {
      border-color: $primary_action_c;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.subscription-upload {
  display: flex;
  height: $input_h;
  line-height: $input_h;
  background-color: $background_secondary_c;
  border-radius: $border_radius_primary;
  button {
    @include reset-button();
    position: relative;
    border: 1px solid $dividing_c;
    border-radius: $border_radius_primary;
    background-color: $background_c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    color: $text_c;
    @include secondary-bold();
    white-space: nowrap;
  }
  &__file {
    padding: 0 0.5rem;
    font-size: 0.8rem;
    overflow: hidden;
  }
}