.video-player-wrapper {
  margin: -6rem 0 5rem;
  &--embed{
    margin: 0 !important;
  }
  @include size-max(tablet) {
    margin: -6rem 0 4rem;
  }
  @include size-max(mobile) {
    margin: 0 0 2rem;
  }
}

.private-wrapper{
  @include size-min(laptop) {
    margin: -4rem 0 5rem !important;
  }
}

.video-player {
  box-shadow: 0 16px 32px 0 $shadow_c;
  &--empty {
    padding: 10rem 0;
    justify-content: center;
    width: 100%;
    background-color: white;
    position: relative;
  }
  @include size-max(tablet) {
    &--empty {
      padding: 6rem 0;
      background-color: white;
      position: relative;
    }
  }
  @include size-max(mobile) {
    margin-right: -15px;
    margin-left: -15px;
    box-shadow: unset;
    @include border($pos: bottom);
    &--empty {
      padding: 2rem 0;
      background-color: white;
    }
  }
}

.header-logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  &__item {
    height: $player_header_logo_h;
    width: auto;
  }
  @include size-max(mobile) {
    padding: 1rem;
    &__item {
      height: $player_header_logo_h - 10px;
    }
  }
}