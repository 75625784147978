.step-icon {
  line-height: $circle_with_image_w;
  color: $positive_c;
  font-size: 1.8rem;
  @include size-max(laptop) {
    line-height: $circle_with_image_md_w;
  }
  @include size-max(tablet) {
    line-height: $circle_with_image_sm_w;
    font-size: 1.2rem;
  }
}

.steps-row {
  .step-container {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: space-around;
    position: relative;
      &:last-child {
        .step-icon {
          visibility: hidden;
        }
      }
    .step-icon {
      @include size-max(mobile) {
        display: none;
      }
    }
  }
}

.step-icon-xs {
  @include size-max(tablet) {
    position: absolute;
    top: 0;
    right: 1rem;
    >i {
      line-height: $circle_with_image_md_w;
      font-size: 1.5rem;
      position: relative;
      z-index: 10;
    }
    &.slick-disabled {
      visibility: hidden;
    }
  }
}

