.tile {
  padding: 7rem 0;
  background-color: $background_c;
  &--secondary {
    background-color: $background_secondary_c;
  }
  &__row {
    align-items: center;
    &--nocenter {
      align-items: unset;
    }
  }
  &__description {
    >p {
      font-size: 1rem;
      line-height: 1.8rem;
    }
    ol {
      list-style: none;
      counter-reset: li;
      >li {
        padding: 0.5rem 0;
        color: $text_c;
        counter-increment: li;
        &:before {
          content: counter(li);
          color: $primary_action_c;
          display: inline-block;
          width: 1.5em;
          margin-left: -1.5em;
          @include secondary-medium();
        }
      }
    }
    ul {
      list-style: none;
      >li {
        padding: 0.5rem 0;
        color: $text_c;
        &::before {
          content: "•";
          color: $primary_action_c;
          width: 1.5em;
          margin-left: -1.5em;
          display: inline-block;
        }
      }
    }
  }
  &__image {
    text-align: center;
    >img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__subtitle {
    @include secondary-bold();
    text-transform: uppercase;
    padding: 0.8rem 0;
  }
  &__content {
    &--center {
      text-align: center;
      margin-bottom: 4rem;
    }
  }
  &__link {
    @include secondary-bold();
    display: block;
    font-size: 1rem;
    padding-top: 1rem;
    color: $secondary_action_c;
    >i {
      font-size: 0.8rem;
      padding-left: 0.5rem;
      @include transition((padding-left));
    }
    &:hover {
        color: $secondary_hover_c;
        >i {
          padding-left: 1rem;
        }
    }
  }
  &__number {
    color: $secondary_action_c;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    @include secondary-bold();
  }
  &__text {
    &--margin {
      margin-top: 2rem;
    }
    &--right {
      text-align: right;
    }
  }
  @include size-max(tablet) {
    padding: 5rem 0;
  }
  @include size-max(mobile) {
    padding: 2.5rem 0;
    &__row {
      &--left {
        flex-direction: column-reverse;
      }
      &--nocenter {
        text-align: center;
      }
    }
    &__image {
      margin-top: 1.8rem;
    }
    &__content {
      &--center {
        margin: 0;
      }
    }
    &__text {
      font-size: 1.2rem;
    }
  }
}