.hidden {
  display: none;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg {
  display: none;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}