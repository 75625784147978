.video-tile {
  width: 100%;
  @include gutter();
  a {
    &:focus {
      outline: none;
    }
  }
  &__image {
    border-radius: $border_radius_primary;
    @include bg();
    height: 13rem;
  }
  &__description {
    padding: 0.5rem 0;
  }
  &__title {
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: $headline_c;
    @include secondary-bold();
    @include transition((color));
  }
  &__text {
    font-size: 0.85rem;
    line-height: 1.7rem;
    color: $text_c;
    @include reset-list();
    display: flex;
    align-items: center;
    >li {
      display: flex;
      align-items: center;
      padding-right: 1rem;
      position: relative;
      &.dot {
        @include before() {
          content: "•";
          margin-left: -10px;
        }
      }
      >i, >img {
        margin-right: 0.5rem;
        display: inline-block;
      }
      >img {
        max-height: 16px;
        max-width: 16px;
      }
    }
  }
  &:hover {
    .video-tile__title {
      color: $secondary_action_c;
    }
  }
}