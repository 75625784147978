.blog-post-section {
  padding-top: 2rem;
  &__image {
    @include bg();
    @include size(100%);
    @include transition((transform));
    &--small {
      height: $blog_post_tile_small_image_h;
      max-height: $blog_post_tile_small_image_h;
      overflow: hidden;
      border-radius: $border_radius_primary;
    }
    &--big {
      @include before() {
        @include fill-parent();
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.4));
      }
    }
  }
  &__big-content {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
  }
  &__date {
    @include secondary-regular();
    color: $text_c;
    font-size: 0.85rem;
    line-height: 1.2rem;
  }
  &__last {
    padding-top: 25px;
  }
  &__item, &__image-wrapper {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    font-size: 0;
    display: block;
    border-radius: $border_radius_primary;
    &--small {
      height: $blog_post_tile_small_h;
      h3 {
        margin: 0.5rem 0;
        overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       line-height: 1;
       max-height: 48px;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
        @include size-max(tablet){
          max-height: 40px;
        }
        @include size-max(mobile){
          max-height: 32px;
        }
      }
    }
    &:hover{
        @include size-min(laptop){
            .blog-post-section__image {
                transform: scale(1.1);
            }
        }
    }
    &--big {
      height: $blog_post_tile_small_h*2 + 25;
      h3 {
        color: $headline_inverse_c;
        font-size: 2.8rem;
        line-height: 3rem;
        padding: 1rem 0 2rem;
        margin: 0;
      }
      .link {
        color: $text_inverse_c;
      }
      .blog-post-section__date {
        font-size: 1rem;
        color: $text_inverse_c;
      }
    }
  }
  &__xs {
    .blog-post-section__item--small {
      height: $blog_post_tile_xs_h;
      width: 19rem;
      min-width: 19rem;
      max-width: 19rem;
      @include gutter();
    }
  }
}