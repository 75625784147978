.sport-filter {
  display: flex;
  flex-wrap: nowrap;
  height: $main_filter_h;
  border-radius: $border_radius_primary;
  background-color: $background_c;
  box-shadow: 0 20px 32px 10px $shadow_c;
  &__form {
    height: 100%;
    position: relative;
    @include transition((border-color));
    .el-input__inner {
      height: $main_filter_h;
      line-height: normal;
      border: 1px solid transparent;
      color: $text_c;
      font-size: 1.2rem;
      padding-top: 0.8rem;
      border-radius: 0;
      display: block;
      @include secondary-regular();
      @include placeholder() {
        color: $text_c;
      }
    }
    + .sport-filter__form {
      @include transition((border-right-color));
    }
  }
  &__item {
    &:not(:first-child) {
      .el-input__inner {
        border-left: 1px solid $dividing_c;
      }
    }
  }
  &__form {
    &:hover {
      .el-input__inner {
        border: 1px solid $secondary_action_c;
      }
      + .sport-filter__form {
        .el-input__inner {
          border-left: 1px solid transparent;
        }
      }
    }
  }
  &__select {
    display: none;
    min-width: 15rem;
    @include transition((border-right, border-left));
    &--noborder {
      .el-input__inner {
        border-right: 1px solid $dividing_c;
      }
    }
    @include size-min(tablet) {
      .el-input__suffix {
        padding-top: 0.8rem;
      }
    }
    @include size-max(laptop) {
      min-width: 12rem;
    }
    @include size-max(tablet) {
      min-width: 10rem;
    }
  }
  &__input {
    .el-input__suffix {
      right: unset;
      left: 1rem;
      top: 1rem;
      position: absolute;
      font-size: 0.9rem;
      @include secondary-bold();
      color: $secondary_action_c;
      label {
        color: $secondary_action_c;
      }
    }
    .el-input__prefix {
      left: 0.5rem;
      padding-top: 0.8rem;
    }
    .el-input__inner {
      border-radius: $border_radius_primary;
      padding-left: 2.5rem;
      padding-right: 1rem;
      &:focus{
        line-height: normal;
      }
    }
  }
  &__button {
    line-height: $main_filter_h;
    >button {
      padding: 0 1.8rem;
      margin: 0 1.6rem;
      border: none;
      height: $main_filter_button_h;
      line-height: $main_filter_button_h;
      background-color: $secondary_action_c;
      @include transition((background-color));
      >span {
        font-size: 1rem;
        text-transform: uppercase;
        @include secondary-medium();
      }
      &:hover {
        background-color: $secondary_hover_c;
      }
    }
  }
  @include size-max(mobile) {
    padding: 2rem 0;
    display: block;
    flex-wrap: unset;
    flex-direction: column;
    height: auto;
    padding: 0;
    &__form {
      width: 100%;
      height: $main_filter_xs_h;
      line-height: $main_filter_xs_h;
      .el-input__inner {
        height: $main_filter_xs_h;
        line-height: normal;
        font-size: 1rem;
        padding: 0 1rem;
        &:focus {
          border-color: transparent;
        }
      }
      &:hover {
          .el-input__inner {
            border-color: transparent;
          }
      }
      .el-input.is-focus {
        .el-input__inner {
          border-color: transparent;
        }
      }
    }
    &__select {
      border: none;
      border-top: 1px solid $dividing_c;
    }
    &__button {
      line-height: unset;
      >button {
        margin: 1rem 0 0 0;
        width: 100%;
      }
    }
  }
}

.sport-filter-wrapper {
  padding: 1rem 0 5rem;
  @include size-max(tablet) {
    padding: 1rem 0 3rem;
  }
  @include size-max(mobile) {
    opacity: 0;
    height: 0;
    padding: 0;
    @include transition((opacity, height));
    &--open {
      opacity: 1;
      height: 2.5*$main_filter_xs_h;
      margin-top: 1rem;
    }
  }
}

.filter-label {
    position: absolute;
    left: 1.8rem;
    top: 1rem;
    font-size: 0.9rem;
    @include secondary-bold();
    color: $secondary_action_c;
    white-space: nowrap;
    @include size-max(mobile) {
      display: none
    }
}

.hp-filter-button-xs {
  position: relative;
  text-align: left;
  padding: 0 1rem;
  i {
    @include absolute-center($direction: vertical);
    right: 1rem;
    opacity: 0;
    @include transition((opacity));
    &.show {
      opacity: 1;
    }
  }
}