.link {
  color: $text_c;
  font-size: 1.2rem;
  @include transition((color));
  @include secondary-regular();
  >i {
    margin-left: 0.2rem;
    vertical-align: middle;
  }
  &:hover {
    color: $secondary_action_c;
    cursor: pointer;
  }
  &--broadcast{
    &:hover {
    color: $primary_action_c;
    cursor: pointer;
  }
  }
}