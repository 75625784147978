.newsletter {
  align-items: center;
  &__input {
    width: 100%;
    min-height: 3.5rem;
    padding: 1rem;
    border-radius: $border_radius_primary;
    @include border();
    @include transition((border-color));
    @include placeholder() {
      color: $text_c;
    }
    &:focus, &:hover {
      outline: none;
      border-color: $secondary_action_c;
    }
  }
  &__button {
    @include absolute-center($direction: vertical);
    right: 1rem;
  }
  &__wrapper {
    position: relative;
    >label {
      display: none;
    }
  }
  &__decsription {
    color: $text_c;
  }
  &__consent {
    font-size: 0.85rem;
    margin: 1rem 0 0 1rem;
    position: relative;
    >input {
      display: none;
    }
  }
  &__checkbox {
    display: none;
    + label {
      padding-left: 1.5rem;
      @include secondary-medium();
      @include before() {
        @include size($newsletter_checkbox_w);
        @include absolute-center($direction: vertical);
        background-color: $background_c;
        border: 1px solid $secondary_action_c;
        border-radius: 2px;
        left: 0;
      }
      @include after() {
          @include size($newsletter_checkbox_w - 4);
        @include absolute-center($direction: vertical);
          background-color: $background_c;
          left: 2px;
      }
    }
    &:checked {
      + label {
        &:before {
            border-color: $secondary_action_c;
        }
        &:after {
            background-color: $secondary_action_c;
        }
      }
    }
  }
  @include size-max(mobile) {
    h3 {
      text-align: center;
      font-size: 1.5rem;
    }
  }
}