.detail-filter {
  @extend .sport-filter;
  border-radius: $border_radius_primary;
  position: relative;
  z-index: 1;
  &__item {
    @extend .sport-filter__form;
    flex: 0 0 25%;
    max-width: 25%;
    .el-input__inner {
      height: $main_filter_h;
      line-height: $main_filter_h;
      border: 1px solid transparent;
      display: block;
      @include placeholder() {
        line-height: $main_filter_h;
      }
    }
    &:not(:first-child) {
        .el-input__inner {
          border-left: 1px solid $dividing_c;
        }
      }
    &:hover {
      .el-input__inner {
        border: 1px solid $secondary_action_c;
      }
      + .detail-filter__item {
        .el-input__inner {
          border-left: 1px solid transparent;
        }
      }
    }
    &:first-child {
      .el-input__inner {
        border-top-left-radius: $border_radius_primary;
        border-bottom-left-radius: $border_radius_primary;
      }
    }
    &:last-child {
      .el-input__inner {
        border-top-right-radius: $border_radius_primary;
        border-bottom-right-radius: $border_radius_primary;
      }
    }
  }
  @include size-max(mobile) {
    margin: 2rem 0;
    display: block;
    flex-wrap: unset;
    flex-direction: column;
    height: auto;
    &__item {
      flex: unset;
      max-width: unset;
      width: 100%;
      height: $main_filter_xs_h;
      line-height: $main_filter_xs_h;
      border: none;
      border-top: 1px solid $dividing_c;
      &:first-child {
        border-top: none;
      }
      .el-input__inner {
        height: $main_filter_xs_h;
        line-height: $main_filter_xs_h;
        font-size: 1rem;
        padding: 0 1rem;
        border-right: none;
        border-left: none !important;
        @include placeholder() {
          font-size: 1rem;
          line-height: $main_filter_xs_h;
        }
        &:focus {
          border-color: transparent;
        }
      }
      &:hover {
        .el-input__inner {
          border-color: transparent;
        }
      }
      .el-input.is-focus {
        .el-input__inner {
          border-color: transparent;
        }
      }
      &:last-child {
        .el-input__inner {
          border-bottom-left-radius: $border_radius_primary;
        }
      }
    }
  }
}

.detail-filter-wrapper {
  margin-top: 2rem;
  margin-bottom: -$main_filter_h/2;
  h3 {
    margin-bottom: 2rem;
  }
  @include size-max(mobile) {
    margin-bottom: 0;
    h3 {
      padding-top: 1rem;
      font-size: 1.5rem;
      text-align: center;
    }
  }
}