.channel-content {
  padding: 11rem 0 3rem;
  margin-top: -$main_filter_h/2;
  @include size-max(mobile) {
    padding: 2rem 0;
    margin-top: 2rem;
  }
}

.count-text{
  padding-right: 10px;
}

.channel-section-content {
  padding: 2rem 0;
  &__load {
    @include reset-button();
    text-align: center;
    width: 100%;
    >i {
      transform: rotate(90deg);
      display: inline-block;
    }
    @include size-max(mobile) {
      margin: 1.5rem 0 2rem;
    }
  }
  &__item-wrapper {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    @include gutter();
    padding-bottom: 2rem;
    .video-tile {
      padding: 0;
    }
    @include size-max(mobile) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-bottom: 0.5rem;
    }
  }
  @include size-max(mobile) {
    padding: 0.5rem 0;
    border-bottom: none;
  }
}

.channel-section-item {
  width: 100%;
  min-height: $chanel_item_h;
  border: 1px solid $dividing_c;
  border-radius: $border_radius_primary;
  background-color: $background_c;
  display: flex;
  padding: 1rem;
  align-items: center;
  @include transition((box-shadow));
  &__image {
    width: 25%;
    margin-right: 0.8rem;
    >img {
      width: auto;
      max-width: 100%;
      max-height: 4rem;
      height: auto;
    }
  }
  &__description {
    h5 {
      margin: 0;
      line-height: 1;
      @include transition((color));
    }
  }
  &__info {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: $text_c;
    display: flex;
    align-items: center;
    img {
      max-width: 1.2rem;
      width: auto;
      max-height: 1rem;
      height: auto;
      margin-right: 0.5rem;
    }
    >span:last-child{
      white-space: nowrap;
    }
    >i {
      padding: 0 0.5rem;
      font-size: 0.8rem;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
  }
  &__num {
    padding-left: 1rem;
    position: relative;
    @include before() {
      @include size(4px);
      background-color: $text_c;
      border-radius: 50%;
      left: 0.5rem;
      @include absolute-center($direction: vertical);
    }
  }
  &:hover {
    box-shadow: 0 16px 32px 0 $shadow_c;
    h5 {
      color: $secondary_action_c;
    }
  }
  @include size-max(mobile) {
    height: $chanel_item_h;
    &__image {
      text-align: center;
      >img {
        width: auto !important;
        max-height: 4rem;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.channel-section {
  border-bottom: 1px solid $dividing_c;
  margin-bottom: 2.5rem;
  @include size-max(mobile) {
    border-bottom: none;
  }
}

#channel-list-static-placeholder {
  position: relative;
  z-index: 3;
}