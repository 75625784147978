.club-section-content {
  padding: 0.5rem
}

.club-section-item {
  width: 100%;
  min-height: 124px;
  border: 1px solid $dividing_c;
  border-radius: $border_radius_primary;
  background-color: $background_c;
  @include flex-center();
  padding: 0.5rem;
  flex-direction: column;
  @include transition((box-shadow));
  &__image {
    padding: 0;
    > img {
      max-width: 100%;
      max-height: 3rem;
      width: auto;
      height: auto;
    }
  }
  &__description {
    margin-top: 0.5rem;
    h5 {
      margin: 0;
      line-height: 1.3;
      font-size: 0.9rem;
      @include transition((color));
    }
  }
  &:hover {
    box-shadow: 0 8px 16px 0 $shadow_c;
    cursor: pointer;
    h5 {
      color: $secondary_action_c;
    }
  }
}

.club-section-loading {
  width: 100%;
  padding: 1rem 0;
}
