.video-slider {
  &--bordered {
    border-bottom: 1px solid $dividing_c;
    margin-bottom: 5rem;
    .video-slider__carousel {
      padding: 2rem 0 2.5rem;
    }
  }
  &__carousel {
    padding-top: 2rem;
  }
  &__arrow {
    top: 30%;
    transform: unset;
    &.slider-arrow {
      &--prev {
        left: -3%;
        @include size-max(laptop) {
          left: -1%;
        }
        @include size-max(tablet) {
          left: -5%;
        }
      }
      &--next {
        right: -3%;
        @include size-max(laptop) {
          right: -1%;
        }
        @include size-max(tablet) {
          right: -5%;
        }
      }
    }
  }
  @include size-max(mobile) {
    h3 {
      font-size: 1.2rem;
    }
    &--bordered {
      margin-bottom: 0;
      border-bottom: unset;
    }
    &__carousel {
      padding-top: 0.5rem !important;
    }
  }
}