.channel-detail-header {
  align-items: center;

  &__logo {
    width: 100%;
  }

  &__info {
    @extend .channel-section-item__info;
    color: $text_inverse_c;
    @include secondary-regular();
    font-size: 0.9rem;

    i {
      padding: 0 0.5rem;
    }
  }

  h1 {
    color: $text_inverse_c;
    font-size: 4rem;
    line-height: 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 12rem;
    @include primary-bold-italic();
  }

  &__num {
    @extend .channel-section-item__num;
    padding-left: 2rem;

    &:before {
      background-color: $text_inverse_c;
      left: 1rem;
    }
  }

  @include size-max(mobile) {
    &__info {
      color: $text_c;
      justify-content: center;
    }
    &__num {
      &:before {
        background-color: $text_c;
      }
    }
  }
}

.detail-games-result-wrapper {
  @include size-max(mobile) {
    background-color: $background_c;
  }
}

.channel-detail-league {
  justify-content: center;
  display: flex;
  align-items: center;

  > span {
    color: $headline_c;
    font-size: 0.9rem;
    @include secondary-bold();
  }

  > i {
    color: $dividing_c;
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}

.channel-detail-views {
  @include primary-regular();
  white-space: nowrap;
  color: $text_c;
  font-size: 0.9rem;

  > i {
    color: $text_c;
    font-size: 0.8rem;
  }
}

.donation {
  margin-top: 5px;
  margin-left: 7px;
}

.donation-recipient {
  margin-top: 10px;
  margin-left: 5px;
  color: $secondary_action_c;
}

.video-player-wrapper:not(.video-player-wrapper--embed) {
  + .banner-wrapper {
    margin-top: -2rem;

    > div {
      text-align: center;
    }
  }
}

.banner {
  @include bg(contain);
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  > a {
    display: block;
  }

  img {
    visibility: hidden !important;
    max-width: 100%;
  }
}

.banner-wrapper {
  margin-top: 48px;

  .container, .container-fluid {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

.lh-3 {
  line-height: 3rem;
}

.mb-2p{
  margin-bottom: 2px;
}

.svcomments {

  .el-collapse-item__arrow {
    line-height: 25px;
    font-weight: 600;
  }

  .el-collapse-item__wrap, .el-collapse-item__header {
    border: none;
  }

  .badge {
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      background-color: #777;
      border-radius: 10px
    }

  .comment-header {
    font-size: 0.9rem;
    line-height: 1.3rem;

    .datetime {
      font-size: 0.8rem;
      font-weight: lighter;
      color: gray;
    }
  }
}

.reply {
  display: inline;

  .el-collapse, .el-collapse-item, .el-collapse-item__header {
    display: inline;
  }

  .el-collapse-item {
    >div[role='tab']{
      display: inline;
    }

    .el-collapse-item__header {
      color: $secondary_action_c!important;
    }

    .el-collapse-item__arrow{
      display: none;
    }
  }
}

.reply-mock{
  display: inline-block;
  height: 26px
}