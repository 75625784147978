.channel-detail-result {
  display: block;
  width: 100%;
  background-color: $background_c;
  padding: 1rem 0;
  margin-bottom: 2px;
  @include transition((box-shadow));
  position: relative;
  &__right {
    display: flex;
    align-items: center;
    >div {
      width: 100%;
      align-items: center;
    }
  }
  &__info {
    display: flex;
    i {
      color: $dividing_c;
      margin: 0 0.5rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
  &__date {
    color: $text_c;
  }
  &__views {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__icon {
    font-size: 1.6rem;
    margin-left: 2rem;
    color: $headline_c !important;
    @include transition((color));
  }
  &__selected {
    box-shadow: 0 5px 20px 0 $shadow_secondary_c;
    z-index: 10;
  }
  &:hover {
    box-shadow: 0 5px 20px 0 $shadow_secondary_c;
    z-index: 10;
    .channel-detail-result__icon {
      color: $secondary_action_c !important;
    }
  }
  @include size-max(tablet) {
    @include border();
    border-radius: $border_radius_primary;
    padding: 0;
    margin-bottom: 1rem;
    &__right {
      padding: 1rem;
      >div {
        width: unset;
        align-items: unset;
      }
    }
    &__league {
      order: 1;
      justify-content: flex-start;
      @include secondary-medium();
      >i {
        margin-left: 0;
      }
    }
    &__date {
      order: 2;
      margin: 0.5rem 0;
      .views {
        margin-left: 1rem;
        @include primary-regular();
        >i {
          color: $text_c;
        }
      }
    }
    &__views {
      order: 3;
      justify-content: flex-start;
    }
    &__icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $primary_action_c;
      @include secondary-bold();
      font-size: 1rem;
      margin-top: 1.5rem;
    }
    &__icon {
      color: $primary_action_c !important;
      font-size: 1rem;
    }
  }
}

.channel-detail-results-wrapper {
  margin-top: 2rem;
  @include size-max(mobile) {
    margin-top: 0;
  }
}
.channel-detail-results-load {
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include size-max(mobile) {
    margin-top: 0;
  }
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    .add-button__icon {
      border-color: $text_inverse_c;
    }
  }
}