.playlist {
    background-color: $background_c;
    height: 100%;
    &__total {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--mobile{
            display: none;
        }
    }
    &__sum {
        display: flex;
        align-items: center;
    }
    &__live {
        width: 5px;
        height: 5px;
        font-size: 0;
        background: $primary_action_c;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }
    &__list {
        @include reset-list();
    }
    &__item {
        @include reset-list();
        padding-right: 0.8rem;
        background-color: $player_item_background_c;
        @include border($pos: top);
        @include transition((background-color));
        > a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: 0.8rem;
            padding-right: 0;
        }
        &--active {
            background-color: $background_c;
        }
        &:hover {
            background-color: $player_item_background_hover_c;
            cursor: pointer;
            .playlist__hover-icon {
                opacity: 1;
            }
            .channel-detail-views {
                opacity: 0;
            }
        }
        &:last-child {
            @include border($pos: bottom);
        }
    }
    &__type {
        font-size: 0.9rem;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        .minute {
            @include primary-bold-italic();
            color: $text_inverse_c;
            height: $player_type_h;
            line-height: $player_type_h;
            min-width: $player_type_h;
            text-align: center;
            background-color: $text_c;
            border-radius: $border_radius_primary;
            display: inline-block;
        }
        .state {
            text-transform: uppercase;
            @include primary-bold();
            color: $text_c;
            padding: 0 0.5rem;
            display: inline-block;
        }
        &--action {
            .minute {
                background-color: $player_action_c;
                @include border-radius($direction: right, $radius: 0);
            }
            .state {
                color: $player_action_c;
                border: 1px solid $player_action_c;
                @include border-radius($direction: right);
                height: $player_type_h;
                line-height: $player_type_h;
            }
        }
        &--special {
            .minute {
                background-color: $player_special_c;
            }
            .state {
                color: $player_special_c;
            }
        }
        &--live {
            .minute {
                background-color: $secondary_action_c;
            }
            .state {
                color: $secondary_action_c;
            }
        }
    }
    .channel-detail-views {
        opacity: 1;
        @include transition((opacity));
    }
    &__team {
        font-size: 0.9rem;
        color: $headline_c;
        @include primary-regular();
        flex: 1;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0.5rem;
    }
    &__hover-icon {
        position: absolute;
        right: 0;
        font-size: 1.8rem;
        opacity: 0;
        @include transition((opacity));
    }
    &__button-mobile {
        margin: 1rem 1rem 2rem;
        width: calc(100% - 2rem);
    }
    @include size-max(mobile) {
        &__total {
            display: none;
            &--mobile{
                display: flex;
            }
        }
        &__button-mobile {
            margin: 1rem 1rem 0;
        }
        &__item {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.playlist-wrapper-hidden {
    height: 100%;
    overflow: hidden;
    position: relative;
    @include size-max(tablet) {
        height: auto;
    }
}

.playlist-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: -17px;
    height: calc(100% + 17px);
    width: 100%;
    z-index: 1;
    @include size-max(tablet) {
        position: relative;
        display: block;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        height: auto;
        &__load {
            text-align: center;
            padding: 1.8rem 0;
            > i {
                transform: rotate(90deg);
                display: inline-block;
            }
        }
    }
}

.playlist-wrapper-background {
    background-color: $background_c;
    height: 100%;
    padding: 2rem 0;
}
