.post-item {
  margin-bottom: 3.5rem;
  &__image {
    border-radius: $border_radius_primary;
    @include bg();
    height: 15rem;
  }
  &__description {
    padding: 0.5rem 0;
  }
  h3 {
    font-size: 1.2rem;
    line-height: 1.7rem;
    @include transition((color));
    margin: 0;
    @include text-limit(2, 1.7rem);
  }
  &__date {
    font-size: 0.85rem;
    line-height: 1.7rem;
    color: $text_c;
    margin: 0;
  }
  &:hover {
    h3 {
      color: $secondary_action_c;
    }
  }
  @include size-max(tablet) {
    margin-bottom: 2rem;
  }
}