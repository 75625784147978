.blog-banner {
  width: 100%;
  padding: 13rem 0 4rem;
  @include border-radius();
  @include bg();
  @include overlay($radius: $border_radius_primary);
  h2 {
    color: $headline_inverse_c;
    font-size: 2.8rem;
  }
  &__date {
    font-size: 1rem;
    @include secondary-regular();
    color: $text_inverse_c;
    margin-bottom: 0.5rem;
  }
  &__subtitle {
    font-size: 1.2rem;
    @include secondary-regular();
    color: $text_inverse_c;
    line-height: 1.5;
    @include text-limit(3, 1.5);
  }
  &__content {
    position: relative;
    z-index: 2;
  }
  .link {
    margin-top: 2rem;
    color: $text_inverse_c;
  }
  &:hover {
    .link {
      color: $secondary_hover_c;
    }
  }
  @include size-max(tablet) {
    padding: 8rem 0 2rem;
  }
  @include size-max(mobile) {
    background-image: unset !important;
    padding: 0;
    &:after {
      display: none;
    }
    h2 {
      font-size: 1.8rem;
      line-height: 1.3;
      color: $headline_c;
      margin: 0.5rem 0 1rem;
    }
    &__subtitle, &__date {
      color: $text_c;
      font-size: 1rem;
    }
    .link {
      color: $secondary_action_c;
    }
    &__image-xs {
      @include bg();
      height: 17rem;
      width: 100%;
      margin-bottom: 1rem;
      @include border-radius();
    }
  }
}
