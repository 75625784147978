/* icon font path, required */
$--font-path: '~element-ui/lib/theme-chalk/fonts';
$grid-columns: 16 !default;
$--color-primary: #1ed360 !default;
$--background-color-base: #f2f2f2 !default;
$--border-color-lighter: #c0c0c0 !default;
$--pagination-button-height: 30px !default;

@import "~bootstrap/scss/bootstrap-grid";

@import "~video.js/src/css/video-js";
@import "~element-ui/packages/theme-chalk/src/index";
@import "~literallycanvas/scss/literallycanvas";
@import "~videojs-contrib-ads/src/plugin";
@import "~videojs-ima/dist/videojs.ima";