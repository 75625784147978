.navbar {
  padding: 1rem;
  background-color: $background_c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__logo {
    padding-left: 0!important;
    height: 100%;
    max-height: 3rem;
    @include gutter();
    padding-right: 3rem;
    margin-bottom: 0;
    h1, h2 {
      all: unset;
      margin-bottom: 0;
    }
    span {
      display: none;
    }
    img {
      height: 100%;
      max-height: 3rem;
      &.hide {
        display: none;
      }
      &.show {
        display: block;
      }
    }
    @include size-max(tablet) {
      padding-right: 0.5rem;
    }
  }
  &__left{
    display: flex;
    align-items: center;
  }
  &__right{
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 16px;
    font-weight: 600;
  }
  &__center{
    display: flex;
    align-items: center;
    gap: 3rem !important;
    font-size: 16px;
    font-weight: 600;
  }
  &__center-margin-right{
    @include size-min(desktop) {
      margin-right: 11%;
    }
  }
  &__center-margin-left{
    margin-left: 5%;
  }
  &__link {
    height: 40px;
    display: flex;
    align-items: center;
    @include gutter();
    >a, >span {
      text-decoration: none;
      color: $text_c;
      @include secondary-medium();
      @include transition((color));
      &:hover {
        color: $secondary_action_c;
        cursor: pointer;
      }
    }
    &__new{
      @include gutter();
      >a, >span {
        text-decoration: none;
        font-size: 16px;
        font-weight: 600 !important;
        color: #000000;
        @include secondary-medium();
        @include transition((color));
        &:hover {
          color: $primary-action_c;
          cursor: pointer;
        }
      }

      &.authenticated {
         @include size-min(desktop) {
          margin-right: 15rem;
        }
      }
    }
  }
  &__menu {
    display: inline-block;
    @include gutter();
    >i {
      font-size: 1.2rem;
      @include transition((color));
    }
    &:hover {
      color: $secondary_action_c;
      cursor: pointer;
    }
  }
  &__button {
    border-radius: 30rem;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
  }
  &__select {
    @include gutter();
  }
  @include size-max(mobile) {
    overflow-x: hidden;
    border-bottom: 1px solid $dividing_c;
    &__logo {
      padding: 0;
      img {
        max-height: 1.8rem;
        height: auto;
        width: auto;
      }
    }
    &__icon-xs {
      color: #000000;
      padding: 0;
      >i {
        font-size: 1.5rem;
      }
    }
  }
  &.fixed {
    position: fixed;
    box-shadow: 0 16px 32px 0 $shadow_c;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    @include transition((transform));
    .sport-filter {
      min-width: 38rem;
      box-shadow: none;
      border-radius: $border_radius_primary;
      height: $main_filter_navbar_h;
      margin-left: 2rem;
      .filter-label, .el-input__suffix-inner>label {
        display: none;
      }
      &__button {
        display: none;
      }
      @include size-max(mobile) {
        &__form {
          width: 60%;
        }
      }
      .sport-filter__select--noborder {
        .el-input__inner {
          border-right: 1px solid $dividing_c;
        }
        input {
          border-bottom-right-radius: $border_radius_primary;
          border-top-right-radius: $border_radius_primary;
        }
      }
      &__select {
        .el-input__suffix {
          padding-top: 0;
        }
      }
      &__input {
        .el-input__prefix {
          padding-top: 0;
        }
      }
      .el-input__inner {
        height: $main_filter_navbar_h - 2;
        line-height: $main_filter_navbar_h - 2;
        font-size: 1rem;
        border: 1px solid $dividing_c;
        padding-top: 0;
        @include placeholder() {
          font-size: 1rem;
          line-height: $main_filter_navbar_h - 2;
        }
        &:hover {
          border: 1px solid $secondary_action_c;
          z-index: 3;
          position: relative;
          background: transparent;
        }
      }
    }
    .navbar__link--none {
      display: none;
    }
    .navbar__logo {
      padding-right: 0;
    }
    @include size-max(laptop) {
      .sport-filter {
        min-width: 24rem;
        margin-left: 1rem;
      }
      .navbar__link {
        white-space: nowrap;
      }
      .navbar__button {
        white-space: nowrap;
        font-size: 0.8rem;
      }
    }
    @include size-max(tablet) {
      display: none;
    }
  }
  &.opacity {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-120%);
  }
  &.open-nav {
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
    height: auto;
  }
  .hide {
    display: none;
  }
  &--height {
    height: 5rem;
    @include size-max(mobile) {
      height: 5rem;
    }
  }
}
.newnav {
  & > ul > li {
		position: relative;
		@include size-max(tablet) {
			overflow: hidden;
		}

		&:hover > a {
			color: hsl(341deg, 96%, 55%);

			svg {
				transform: rotate(180deg);
				path {
					stroke: hsl(341deg, 96%, 55%);
				}
			}
		}

		&:hover span {
			height: auto;
		}

		&:hover ul,
		&:active ul {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}

		& > a {
			font-weight: 600;
			color: black;
			transition: all 0.3s ease-out;
			padding: 0.5rem 0;
			display: block;
            @include size-min(tablet) {
              padding: 0;
            }

			svg {
				transition: all 0.3s ease-out;
			}
		}

		span {
			display: block;
			@include size-max(tablet) {
				height: 0;
			}
		}

		ul {
			left: 0;
			top: 100%;
			white-space: nowrap;
			background-color: white;
			padding: 0.75rem !important;
			border-radius: 0.7rem;

			@include size-min(laptop) {
				transition: all 0.3s ease-out;
				flex-direction: column;
				display: flex;
				position: absolute;
				visibility: hidden;
				opacity: 0;
				transform: translateY(1rem);
				box-shadow: 0 4px 6px -2px #10182808;
				border: 0.02rem solid #eaecf0;
			}

			li.active a,
			a:hover {
				background-color: #f5f6f8;
			}

			a {
				display: flex;
				padding: 0.7rem;
				gap: 0.5rem;
				border-radius: 0.5rem;
				transition: all 0.3s ease-out;
			}

			span {
				transition: all 0.3s ease-out;
				color: black;
				font-size: 0.9rem;
				font-weight: 600;
			}

			small {
				display: block;
				color: #475467;
				font-weight: 400;
			}
		}
	}
}

.navbar-filter {
  display: none;
  &.show {
    display: block;
  }
}

.fixNav {
  padding-top: 68px;
  .navbar {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    z-index: 3;
    box-shadow: 0 0 0 0 $shadow_c;
    @include transition((box-shadow));
    &.navbarShadow {
      @include size-min(tablet) {
        box-shadow: 0 8px 16px 0 $shadow_c;
      }
    }
  }

  .modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          p {
            font-weight: 600;
            font-size: 1.1rem;
            color: #FA1F63;
          }
          h2 {
            font-size: 1.5rem;
            max-width: 25rem;
            margin: 1rem 0 3rem 0;
            text-transform: none;
            font-weight: 600;
            line-height: 2rem;
            font-style: normal;
          }
        }
      }
    }
  }
}

.solutions {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#solutions-dropdown {
  display: flex!important;
  z-index: 1;
}

.pink-hover:hover {
  > span {
    color: $primary_action_c!important;
  }
}

.qr_back {
  background: #F5F6F8;
  width: 220px;
  height: 220px;
  padding: 24px;
  gap: 16px;
  border-radius: 16px;
}
.qr_bor {
  border: 2px solid #FA1F63;
  border-radius: 10px;
  max-height: 172px;
  max-width: 172px;
}

.gap-1{
  gap: 1rem;
}

.gap-6 {
  gap: 6rem;
}

.profile-icon {
  @include transition((color));
  color: dimgrey;
  font-size: 20px;
  &:hover{
    color: $secondary_action_c;
  }
}