//.download-video{
//  @include only-ios(){
//    display: none;
//  }
//}

.video-js .vjs-control{
  @include size-max(mobile){
    width: 3em;
  }
}