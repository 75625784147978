.image-circle {
    margin-bottom: 2rem;
    @include size($circle_with_image_w);
    border-radius: 50%;
    background-color: $background_c;
    box-shadow: 0 16px 32px 0 $shadow_c;
    display: inline-block;
    position: relative;
    @include border($size: 2px, $color: transparent);
    @include transition((border-color));

    &__content {
        opacity: 1;
        @include transition((opacity));
        @include absolute-center();

        > img {
            max-height: $circle_with_image_w/2;
        }

        > i {
            font-size: 5rem;
            color: $secondary_action_c;
        }
    }

    &__hover {
        opacity: 0;
        top: 32%;
        @include transition((opacity), $delay: 0.2s);
        @include absolute-center($direction: horizontal);

        > i {
            display: block;
            font-size: 5rem;
            color: $secondary_action_c;
        }

        > span {
            display: block;
            text-transform: uppercase;
            @include primary-bold-italic;
            font-size: 1.2rem;
            color: $secondary_action_c;
            padding-top: 0.5rem;
        }
    }

    @include size-max(laptop) {
        @include size($circle_with_image_md_w);
        &__content {
            > img {
                max-height: $circle_with_image_md_w/2;;
            }
        }
    }
    @include size-max(tablet) {
        @include size($circle_with_image_sm_w);
        &__content {
            > img {
                max-height: $circle_with_image_sm_w/2;;
            }

            > i {
                font-size: 3rem;
            }
        }
        &__hover {
            > i {
                font-size: 3rem;
            }

            > span {
                font-size: 0.8rem;
            }
        }
    }
    @include size-max(mobile) {
        &__hover {
            top: 40%;
        }
        @include size($circle_with_image_md_w);
        &__content {
            > img {
                max-height: $circle_with_image_md_w/2;;
            }
        }
    }
}

.hover-info-text {
    text-align: center;
    font-size: 12px;
    display: none;
}

.mid-item {
    &--circle {
        flex-basis: $circle_with_image_w;

        h5, i {
            @include transition((color));
        }

        &:hover {
            &:not(.mid-item--dashboard) {
                cursor: pointer;

                .image-circle {
                    @include border($size: 2px, $color: $secondary_action_c);

                    &__content {
                        opacity: 0;
                    }

                    &__hover {
                        opacity: 1;
                    }
                }

                h5 {
                    color: $secondary_action_c;
                }
            }
        }

        &:focus {
            outline: none;
        }

        @include size-max(laptop) {
            flex-basis: $circle_with_image_md_w;
        }
        @include size-max(tablet) {
            flex-basis: $circle_with_image_sm_w;
            h5 {
                font-size: 0.9rem;
                line-height: 1.2rem;
            }
        }
    }

    &--dashboard {
        cursor: default;
        color: inherit;
        opacity: 0.5;

        &:focus,
        &:hover {
            color: inherit;
        }

        + .step-icon {
            color: $text_c;
            opacity: 0.5;
        }

        .image-circle {
            &__content {
                > i {
                    color: $text_c;
                }
            }
        }

        h5 {
            margin-bottom: 2rem;
        }

        &.complete {
            opacity: 1;

            .image-circle {
                @include border($size: 2px, $color: $secondary_action_c);

                &__content {
                    opacity: 0;
                }

                &__hover {
                    opacity: 1;
                }
            }

            h5 {
                color: $secondary_action_c;
            }

            + .step-icon {
                color: $secondary_action_c;
                opacity: 1;
            }
        }

        &.active {
            cursor: pointer;
            opacity: 1;

            h5 {
                color: $primary_action_c;
            }

            .image-circle {
                &__content {
                    > i {
                        color: $primary_action_c;
                    }
                }
            }

            &:hover {
                .hover-info-text {
                    display: block;
                }

                i {
                    display: none;
                }
            }
        }
    }
}