@mixin size-max($point) {
    @if $point == laptop_l {
        @media (max-width: $break_lg) { @content ; }
    }
    @else if $point == laptop {
        @media (max-width: $break_md_lg - 1) { @content ; }
    }
    @else if $point == tablet {
        @media (max-width: $break_sm_md - 1) { @content ; }
    }
    @else if $point == mobile {
        @media (max-width: $break_xs_sm - 1)  { @content ; }
    }
    @else if $point == small_mobile {
        @media (max-width: $break_min)  { @content ; }
    }
}

@mixin size-min($point) {
    @if $point == desktop {
        @media (min-width: $break_md_lg) { @content ; }
    }
    @else if $point == laptop {
        @media (min-width: $break_sm_md) { @content ; }
    }
    @else if $point == tablet {
        @media (min-width: $break_xs_sm)  { @content ; }
    }
}

@mixin size-only($point) {
    @if $point == laptop {
        @media (min-width: $break_sm_md) and (max-width: $break_md_lg - 1){ @content ; }
    }
    @else if $point == tablet {
        @media (min-width: $break_xs_sm) and (max-width: $break_sm_md - 1) { @content ; }
    }
}

@mixin background-gradient-clip($from-color, $to-color, $fallback-color) {
    background-color: transparent;
    background-image: -webkit-gradient(linear, 0% 100%, 100% 100%, from($from-color), to($to-color));
    background-image: -webkit-linear-gradient(left, $from-color, $to-color);
    background-image: linear-gradient(to right, $from-color, $to-color);
    color: $fallback-color; //fallback color for non -webkit browsers
    -webkit-text-fill-color: transparent; //transparent text color for webkit browsers
    -webkit-background-clip: text; //clip background gradient (image) to text
}

@mixin border-gradient-clip($from-color, $to-color, $fallback-color) {
    border-style: solid;
    -webkit-border-image: -webkit-gradient(linear, 0 0, 100% 0, from($from-color), to($to-color)) 1 100%;
    -webkit-border-image: -webkit-linear-gradient(left, $from-color, $to-color) 1 100%;
    -moz-border-image: -moz-linear-gradient(left, $from-color, $to-color) 1 100%;
    -o-border-image: -o-linear-gradient(left, $from-color, $to-color) 1 100%;
    border-image: linear-gradient(to right, $from-color, $to-color) 1 100%;
}

@mixin to-right-gradient($from-color, $to-color) {
    background-color: $from-color; /* Fallback */
    background-image: -webkit-gradient(linear, 0% 100%, 100% 100%, from($from-color), to($to-color));
    background-image: -webkit-linear-gradient(right, $from-color, $to-color);
    background-image:    -moz-linear-gradient(right, $from-color, $to-color);
    background-image:     -ms-linear-gradient(right, $from-color, $to-color);
    background-image:      -o-linear-gradient(right, $from-color, $to-color);
    background-image: linear-gradient(to right, $from-color, $to-color);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$from-color}', EndColorStr='#{$to-color}')";
}

@mixin to-left-gradient($from-color, $to-color) {
    background-color: $from-color; /* Fallback */
    background-image: -webkit-gradient(linear, 100% 100%, 0% 100%, from($from-color), to($to-color));
    background-image: -webkit-linear-gradient(left, $from-color, $to-color);
    background-image:    -moz-linear-gradient(left, $from-color, $to-color);
    background-image:     -ms-linear-gradient(left, $from-color, $to-color);
    background-image:      -o-linear-gradient(left, $from-color, $to-color);
    background-image: linear-gradient(to left, $from-color, $to-color);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$to-color}', EndColorStr='#{$from-color}')";
}

//center for block element with margins without changing top or bottom margin
@mixin margin-center(){
    margin:{
        left: auto;
        right: auto;
    }
}

//keeps the element in aspect ratio defined by user, element can't be absolute
@mixin responsive-ratio($x,$y) {
    padding-top: unquote( ( $y / $x ) * 100.0 + '%' );
}

//mixin for background image with most used default values
@mixin bg($size: cover, $repeat: no-repeat, $pos: center center, $color: transparent, $image: false){
    background-size: $size;
    background-repeat: $repeat;
    background-position: $pos;
    background-color: $color;
    @if $image {
        background-image: url($image);
    }
}

//mixins for pseudo elements
@mixin pseudo($content: '', $display: block, $pos: absolute){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin before($content: '', $display: block, $pos: absolute){
    &::before{
        @include pseudo($content, $display, $pos);
        @content;
    }
}

@mixin after($content: '', $display: block, $pos: absolute){
    &::after{
        @include pseudo($content, $display, $pos);
        @content;
    }
}

//creates triangle
@mixin triangle($color, $direction, $size: 5px, $position: absolute){
    width: 0;
    height: 0;
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    }
}

//simple function to keep track of z-index values, first item in list have highest z-index, last lowest
@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name)) + 1;
    } @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}

//clear floats
@mixin clearfix {
    @include after($content: '', $display: table, $pos: static){
        clear: both;
    }
}

//reset default list styles
@mixin reset-list(){
    padding: 0;
    margin: 0;
    list-style: none;
}

//reset default link styles
@mixin reset-link(){
    color: #000;
    text-decoration: none;
}

//reset default button styles
@mixin reset-button($pad: 0){
    background: transparent;
    border: none;
    padding: $pad;
    &:focus{
        outline: none;
    }
}

//absolute centering, options - both,horizontal,vertical
@mixin absolute-center($direction: both){
    position: absolute;
    @if $direction == both {
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    } @else if $direction == horizontal {
        left: 50%;
        transform: translateX(-50%);
    } @else if $direction == vertical {
        top: 50%;
        transform: translateY(-50%);
    }
}

//retina images
@mixin retina-image($image, $size: cover) {
    @media (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 2.6/2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
            background-image: url($image);
            background-size: $size;
    }
}

//placeholder style
@mixin placeholder() {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

//helper function for striping units from value
@function strip-units($value) {
  @return $value / ($value * 0 + 1);
}

/*
$break-from - min resolution to scale from
$break-to - max resolution to scale from
$min-size - size on min resolution
$max-size -  size on max resolution
$property-name - name of property (properties) to resize
*/
@mixin dynamic-size($break-from, $break-to, $min-size, $max-size, $property-name...){
    $break-from: strip-units($break-from)/100;
    $break-to: strip-units($break-to)/100;
    $min-size: strip-units($min-size);
    $max-size: strip-units($max-size);
    $viewport: (($min-size - $max-size)/($break-from - $break-to));
    $pixel: $max-size - $break-to * $viewport;
    $viewport: $viewport * 1vw;
    $pixel: $pixel * 1px;
    @each $property in $property-name {
      #{$property}: calc(#{$viewport} + #{$pixel});
    }
}

//transition
@mixin transition($props: (), $duration: $trans_speed, $delay: 0s, $function: linear){
    transition-property: $props;
    transition-duration: $duration;
    transition-delay: $delay;
    transition-timing-function: $function;
}

//borders
@mixin border($size: 1px, $pattern: solid, $color: $dividing_c, $pos: all, $clear: none ) {
    @if $pos == all {
        border: $size $pattern $color;
    } @else {
        border-#{$pos}: $size $pattern $color;
    }
    @if $clear != none {
        border-#{$clear}: none;
    }
}
@mixin border-bottom() {
    @include border($pos: bottom);
}
@mixin border-top() {
    @include border($pos: top);
}
@mixin border-left() {
    @include border($pos: left);
}
@mixin border-right() {
    @include border($pos: right);
}

//grid gutter
@mixin gutter($val: 1rem){
    padding:{
        left: $val;
        right: $val;
    }
}

//give absolute child full size of parent, parent should be relative
@mixin fill-parent($z: 0){
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $z;
}

//set size of element
@mixin size($w, $h: $w){
    width: $w;
    height: $h;
}

//center wrapper content with flex
@mixin flex-center(){
    display: flex;
    justify-content: center;
    align-items: center;
}

//forces the footer to remain at the bottom of page and set size of page content to fill remaining space
@mixin page-full-size($header: header, $content: div, $footer: footer){
    display: flex;
    flex-direction: column;
    height: 100%;
    > #{$header} {
        flex: 0 0 auto;
    }
    > #{$content} {
        flex: 1 0 auto;
    }
    > #{$footer} {
        flex-shrink: 0;
    }
}

// fonts
@mixin declare-font-face($font-family, $font-path, $font-filename, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-family: '#{$font-family}';
        src: url('#{$font-path}/#{$font-filename}.eot');
        src: url('#{$font-path}/#{$font-filename}.eot?#iefix') format('embedded-opentype'),
            url('#{$font-path}/#{$font-filename}.woff2') format('woff2'),
            url('#{$font-path}/#{$font-filename}.woff') format('woff'),
            url('#{$font-path}/#{$font-filename}.ttf') format('truetype'),
            url('#{$font-path}/#{$font-filename}.svg##{$font-filename}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

@mixin max-size($w, $h: $w){
    max-width: $w;
    max-height: $h;
    height: auto;
    width: auto;
}

@mixin border-radius($direction: all, $radius: $border_radius_primary){
    @if $direction == all {
        border-radius: $radius;
    } @else if $direction == top {
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
    } @else if $direction == bottom {
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
    } @else if  $direction == left {
        border-bottom-left-radius: $radius;
        border-top-left-radius: $radius;
    }
    @else if  $direction == right {
        border-bottom-right-radius: $radius;
        border-top-right-radius: $radius;
    }
}

@mixin text-limit($rows, $line-height) {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: $line-height;
   max-height: $rows * $line-height;
   -webkit-line-clamp: $rows;
   -webkit-box-orient: vertical;
}

@mixin overlay($color: $overlay_c, $radius: 0) {
    position: relative;
    @include after() {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $color;
        border-radius: $radius;
    }
}

@mixin truncated-text($flex: 1, $child: false) {
    flex: $flex;
    @if $child {
        min-width: 0;
        >#{$child} {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    } @else {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@mixin only-ios(){
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}

@mixin not-ios(){
  @supports not (-webkit-overflow-scrolling: touch) {
    @content;
  }
}