.video-tutorial {
  padding: 1.8rem 0;
  &__image {
    @include bg();
    height: 16rem;
    border-radius: $border_radius_primary;
    position: relative;
  }
  &__icon {
      @include absolute-center();
      color: $text_inverse_c;
      font-size: 4rem;
      @include transition((color));
  }
  &__text {
    color: $text_c;
    font-size: 1rem;
    line-height: 1.8;
    @include secondary-regular();
  }
  &__title {
    margin: 1rem 0 0.8rem;
    @include transition((color));
  }
  &:hover {
    cursor: pointer;
    .video-tutorial__icon, .video-tutorial__title {
      color: $secondary_action_c;
    }
  }
  @include size-max(mobile) {
    &__image {
      height: 12rem;
    }
  }
}