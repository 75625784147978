$double-menu-height: 48px;
$double-menu-bg-color: #1F191B;
$double-menu-z-index: 11;
$transition-duration: 0.3s;
$active-color: white;
$inactive-color: rgba(255, 255, 255, 0.6);
$regular-padding: 16px 12px 16px 12px;
$double-menu-link-height: $double-menu-height;

@mixin menu-item-mixin {
  transition: $transition-duration ease-out;
}

.double-menu {
  width: 100%;
  height: $double-menu-height;
  background-color: $double-menu-bg-color;
  position: fixed;
  z-index: $double-menu-z-index;

  a {
    @include menu-item-mixin;
    font-size: 14px;
    line-height: 16px;
    padding: $regular-padding;
    height: $double-menu-link-height;
    display: inline-block;
    color: $inactive-color;

    &.active, &:hover, >span.active {
      color: $active-color !important;

      > svg > path {
        fill-opacity: 1;
      }
    }

    &:first-of-type {
      padding-left: 0 !important;
    }

    &.doubleright {
      float: right;
      padding-right: 0 !important;
      display: flex;
      gap: 8px;
      @media (max-width: 991px)  {
        display: none;
      }

      img {
        height: 24px;
        align-self: center;
      }
    }

    > svg > path {
      @include menu-item-mixin;
    }
  }


}