.emoji-btn {
  cursor: pointer;
  font-size: 1.2rem;
}

.emoji-picker {
  user-select: none;
}

.svcomments :target {
      scroll-margin-top: 300px;
}

.counter {
  font-size: 0.8rem;
  font-weight: lighter;
  color: gray;
  white-space: nowrap;
}

 @media (max-width: 388px) {
   .reply .flex-item {
     &:first-child {
       order: 2;
     }

     &:last-child {
       order: 1;
       margin-bottom: 0.5rem;
       padding-left: 0.5rem;
     }
   }
 }

 @media (max-width: 312px) {
   .flex-item {
     &:first-child {
       order: 2;
     }

     &:last-child {
       order: 1;
       margin-bottom: 0.5rem;
       padding-left: 0.5rem;
     }
   }
 }