.info-overlay {
  background-color: $overlay_c;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  h2 {
    color: $headline_inverse_c;
    text-align: center;
    margin: 2rem 0;
  }
  &__scoreboard {
    z-index: 1;
    background-color: transparent;
    justify-content: start;
    align-items: start;
  }
  &__banner-container {
    z-index: 1;
    bottom: 0;
    top: auto;
    left: auto;
    right: auto;
    height: 100px;
    background-color: transparent;
  }
  &__banner {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width: 728px;
    height: 90px;
    background-color: transparent;
    justify-content: center;
    align-items: end;
    align-self: end;
  }
  &__description {
    font-size: 1.2rem;
    text-align: center;
    @include secondary-medium();
  }
  &__apps {
    text-align: center;
    margin: 1rem 0 2rem;
  }
  &__icons {
    text-align: center;
    margin-top: 2rem;
  }
  &__icon {
    color: $text_inverse_c;
    text-align: center;
    margin: 0 2rem;
    display: inline-block;
    i {
      background-color: $primary_action_c;
      @include border-radius();
      @include size(4rem);
      line-height: 4rem;
      text-align: center;
      font-size: 2rem;
      margin-bottom: 1rem;
      @include transition((background-color));
    }
    span {
      font-size: 16px;
      text-transform: uppercase;
      display: block;
      @include primary-bold-italic();
    }
    &:hover {
      cursor: pointer;
      > i {
        background-color: $primary_hover_c;
      }
    }
  }
  @include size-max(mobile) {
    padding: 1rem;
    >img {
      height: 1rem;
    }
    h2 {
      line-height: 1;
      font-size: 1rem;
      margin: 0.5rem 0;
    }
    &__description {
      font-size: 0.9rem;
    }
    &__apps {
      margin: 0.5rem 0;
      img {
        height: 1.5rem;
      }
    }
    &__icons {
      margin: 1rem 0 0;
    }
    &__icon {
      margin: 0 0.5rem 0.5rem;
      >i {
        @include size(2rem);
        line-height: 2rem;
        font-size: 1rem;
      }
      >span {
        font-size: 0.8rem;
      }
    }
  }
}

.player-overlay {
  position: absolute;
  width: 100%;
  bottom: 3em;
  top: 0;
  left: 0;
}