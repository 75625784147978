$basicBorder: 1px solid #ff9600;

$pickerWidth: 61px;
$pickerButtonSize: 30px;
$optionsHeight: 31px;
$optionsButtonSize: $optionsHeight - 3px;

$canvasBackground: #ddd;
$toolbarBackground: #ff9600;
$selectedColor: #fbcf90;
$hoverColor: #da8000;

@mixin fill-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin button-style-1 {
    border: 2px solid transparent;
    border-radius: 3px;

    & {
        text-decoration: none;
        cursor: pointer;
    }

    &.selected:not(.disabled) {
        background-color: $selectedColor;
    }
    &:hover:not(.disabled) {
        border-color: $hoverColor;
    }
    &:active:not(.disabled) {
    }
    &.disabled {
        cursor: default;
        opacity: 0.3;
    }
}

.literally .button-style-1 {
    @include button-style-1;
}

.literally.toolbar-at-top {
    .lc-drawing {
        bottom: 0;
        top: $optionsHeight;
    }
    .lc-options {
        top: 0;
        border-bottom: $basicBorder;
    }
}

.literally.toolbar-at-bottom {
    .lc-drawing {
        bottom: $optionsHeight;
        top: 0;
    }
    .lc-options {
        bottom: 0;
        border-top: $basicBorder;
    }
}

.literally.toolbar-hidden {
    .lc-drawing {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
    .lc-options, .lc-picker {
        display: none;
    }
}

.literally {
    position: relative;
    background-color: $canvasBackground;
    min-height: 400px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: none;
    user-select: none;

    &, & * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    & > * {
        position: absolute;
    }

    .lc-picker {
        top: 0;
        left: 0;
        bottom: 0;
        width: $pickerWidth;
        background-color: $toolbarBackground;
    }

    .lc-drawing {
        right: 0;
        left: 0;
        bottom: 0;
        left: 0;

        & > * {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .lc-drawing.with-gui {
        right: 0;
        left: $pickerWidth;
        cursor: default;

        .polygon-toolbar {
            top: auto;
            height: $optionsHeight;

            .polygon-toolbar-button {
                float: left;
            }
        }

        .text-tool-input {
            &:focus {
                outline: none;
            }
        }
    }

    label {
        font-weight: bold;
    }
}

.literally .lc-picker {
    z-index: 1001;
    border-right: $basicBorder;

    $buttonMargin: 2px;
    $buttonActualSize: $pickerButtonSize - $buttonMargin * 2;

    .toolbar-button {
        width: $buttonActualSize;
        height: $buttonActualSize;
        line-height: $buttonActualSize;
        margin: $buttonMargin;
        padding: 0;
        cursor: pointer;
        text-align: center;

        @include button-style-1;
    }

    .thin-button {
        cursor: pointer;
        clear: both;
        position: relative;
        left: ($pickerWidth - $pickerButtonSize) / 2;
    }

    .fat-button {
        clear: both;
        width: $pickerWidth - 1 - $buttonMargin * 2;
        font-weight: bold;
    }

    .lc-pick-tool, .lc-undo, .lc-redo, .lc-zoom-in, .lc-zoom-out {
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .lc-undo, .lc-redo {
        left: 0;
        float: left;
        clear: none;
    }
}

.literally {
    background-color: transparent;
    .color-well {
        font-size: 10px;
        float: left;
        width: $pickerWidth - 1px;

        &.open {
            background-color: $selectedColor;
        }

        // Don't set position: relative, because we want the pickers to be
        // positioned inside the whole view.
    }

    .color-well-color-container {
        @include button-style-1;
        border: 1px solid #aaa;
        position: relative;
        width: $pickerButtonSize - 2px;
        height: $pickerButtonSize - 2px;
        margin: 1px auto;
        overflow: visible;

        .color-well-checker {
            position: absolute;
            width: 50%;
            height: 50%;
            background-color: black;
        }

        .color-well-checker-top-left {
            border-top-left-radius: 3px;
        }

        .color-well-checker-bottom-right {
            border-bottom-right-radius: 3px;
        }

        .color-well-color {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 3px;
        }
    }

    .color-picker-popup {
        position: absolute;
        z-index: 1;
        background-color: white;
        border: $basicBorder;

        left: $pickerWidth - 1px;
        bottom: $optionsHeight;

        .color-row {
            clear: both;

            .color-cell {
                cursor: pointer;
                width: 20px;
                height: 20px;
                line-height: 20px;
                float: left;

                &:hover, &.selected {
                    border: $basicBorder;
                    line-height: 18px;
                }

                &.transparent-cell {
                    width: 100%;
                }
            }
        }
    }
}

.literally .horz-toolbar {
    height: $optionsHeight;
    background-color: $toolbarBackground;

    .label {
        line-height: $optionsHeight - 1px;
        margin: 0 0.25em 0 0.25em;
        font-size: 12px;
    }

    span {
        line-height: $optionsHeight - 1px;
        margin: 0 0.25em 0 0.25em;
        font-size: 12px;
        float: left;
    }

    .square-toolbar-button {
        @include button-style-1;
        margin: 1px;
        border: 1px solid transparent;
        width: $optionsButtonSize;
        height: $optionsButtonSize;
        float: left;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        label {
            @include fill-container;
            line-height: $optionsButtonSize - 2px;
            margin: auto;
            float: none;
            text-align: center;
        }
    }

    .logo-label {
        position: absolute;
        top: 3px;
        right: 5px;

        img {
            width: 30px;
            height: 24px;
        }

        span {
            float: right;
            font-size: 20px;
            font-weight: bold;
        }
    }
}

.literally .polygon-toolbar {
    position: absolute;
    border-top: $basicBorder;
    border-bottom: $basicBorder;
    width: 100%;
}

.literally.toolbar-at-bottom .polygon-toolbar {
    top: -100%;
}

.literally.toolbar-at-top .polygon-toolbar {
    top: 100%;
}

.literally .lc-options {
    z-index: 1;
    right: 0;
    left: $pickerWidth;

    .lc-font-settings {
        height: $optionsHeight - 1px;
        line-height: $optionsHeight;
        padding-left: 4px;
        background-color: $toolbarBackground;

        input {
            margin: 0 0.5em 0 0;

            &[type=checkbox] {
                margin: 0 0.5em 0 0.5em;
            }
        }

        select {
            background-color: $toolbarBackground;
            font-size: 14px;
        }
    }
}
