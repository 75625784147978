.subscription-option {
  @include border();
  border-radius: $border_radius_primary;
  position: relative;
  @include size-only(tablet) {
    min-height: 1100px;
    background-color: $background_secondary_c;
  }
  &__header {
    background-color: $background_secondary_c;
    text-align: center;
    padding: 2.5rem 1rem 1.5rem;
    @include border-bottom();
    border-top-right-radius: $border_radius_primary;
    border-top-left-radius: $border_radius_primary;
    h3 {
      font-size: 1.5rem;
    }
    &.border-radius {
      border-bottom-right-radius: $border_radius_primary;
      border-bottom-left-radius: $border_radius_primary;
    }
    @include size-max(tablet) {
      border-bottom: none;
    }
  }
  &__state {
    font-size: 2.5rem;
    padding: 0.5rem 0 2rem;
    color: $text_c;
    @include primary-regular();
    &--primary {
      color: $primary_action_c;
    }
  }
  &__for {
    font-size: 1rem;
    color: $text_c;
    margin-top: 0.5rem;
    @include secondary-regular();
  }
  &__description {
    padding: 1rem;
    font-size: 0.8rem;
    color: $text_c;
    line-height: 1.8;
    @include border-bottom();
    >b, >strong {
      color: $headline_c;
    }
    @include size-max(tablet) {
      background-color: $background_secondary_c;
    }
  }
  &__benefits {
    padding: 2rem 1.5rem;
    ul {
      @include reset-list();
      >li {
        @include reset-list();
        padding: 0.5rem 0;
        @include secondary-regular();
        display: flex;
        align-items: center;
        >i {
          color: $secondary_action_c;
          font-size: 1.6rem;
          margin-right: 0.6rem;
        }
      }
    }
    @include size-max(tablet) {
      background-color: $background_secondary_c;
    }
  }
  &__footer {
    background-color: $background_secondary_c;
    border-bottom-right-radius: $border_radius_primary;
    border-bottom-left-radius: $border_radius_primary;
    padding: 1rem;
    @include border-top();
    text-align: center;
    @include size-only(tablet) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    >p {
      font-size: 0.8rem;
      color: $text_c;
      line-height: 1.5;
      margin: 0 0 1.5rem;
    }
  }
  &__show {
    color: $primary_action_c;
    @include secondary-medium();
    font-size: 1rem;
    margin-top: 1.8rem;
    i {
      margin-left: 0.5rem;
      font-size: 0.8rem;
    }
  }
  @include size-min(laptop) {
    display: flex;
    flex-direction: column;
    height: 100%;
    &__content{
      flex: 1;
      background: $background_c;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__benefits{
      margin-bottom: auto;
    }
  }
  @include size-max(tablet) {
    button {
      background-color: transparent;
      color: $primary_action_c;
    }
  }
}

.subscription-options-xs {
  @include size-max(tablet) {
    margin: 0;
  }
}