.social-sites {
  text-align: center;
  h3 {
    margin-bottom: 2.5rem;
  }
  &__item {
    background-color: $background_c;
    border: 0;
    width: 100%;
    border-radius: 30px;
    box-shadow: 0 4px 16px 0 $shadow_secondary_c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 0;
    color: $secondary_action_c;
    font-size: 1.2rem;
    @include primary-bold();
    @include transition((background-color, color));
    >i {
      margin-right: 1rem;
    }
    &:hover {
      background-color: $secondary_action_c;
      color: $text_inverse_c;
      cursor: pointer;
    }
  }
  @include size-max(tablet) {
    &__item {
      font-size: 0.9rem;
      >i {
        margin-right: 0.5rem;
      }
    }
  }
  @include size-max(mobile) {
    &__item {
      margin-bottom: 1rem;
      font-size: 1.2rem;
      >i {
        margin-right: 1rem;
      }
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1.8rem;
    }
  }
}