.video-info {
  background-color: $background_c;
  &__footer {
    padding: 1.2rem 1rem;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    display: flex;
    align-items: center;
  }
  &__date {
    font-size: 0.9rem;
    color: $text_c;
  }
  &__social {
    margin-left: 2px;
    @include border();
    display: inline-block;
    padding: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
    text-transform: uppercase;
    @include primary-bold();
    border-radius: $border_radius_primary;
    > i {
      margin-right: 0.5rem;
      font-size: 0.9rem;
    }
    &--facebook {
      color: $facebook_c;
      border-color: $facebook_c;
      &:hover {
        background-color: $facebook_c;
        color: $text_inverse_c;
      }
    }
    &--whatsapp {
      color: $whatsapp_c;
      border-color: $whatsapp_c;
      &:hover {
        background-color: $whatsapp_c;
        color: $text_inverse_c;
      }
    }
    &--messenger {
      color: $messenger_c;
      border-color: $messenger_c;
      &:hover {
        background-color: $messenger_c;
        color: $text_inverse_c;
      }
    }
    &--twitter {
      color: $twitter_c;
      border-color: $twitter_c;
      &:hover {
        background-color: $twitter_c;
        color: $text_inverse_c;
      }
    }
    &--full_download {
      color: $secondary_action_c;
      border-color: $secondary_action_c;
      width: 100%;
      font-size: larger;
      margin-top: 12px;
      webkit-transition-property: color, background-color;
      transition-property: color, background-color;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;
      text-decoration: none;
      &:hover {
        background-color: $secondary_action_c;
        color: $text_inverse_c;
      }
    }
  }
  &__action {
    @include reset-button();
    font-size: 1rem;
    color: $headline_c;
    margin: 0 0.5rem;
    @include transition((color));
    > i {
      font-size: 1rem;
    }
    &:hover {
      cursor: pointer;
      color: $secondary_action_c;
    }
  }
  &__share {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @include size-max(tablet) {
    .match-results__team-name {
      margin-bottom: 0;
    }
    .match-results {
      justify-content: center;
    }
    &__footer {
      @include border($pos: top);
    }
  }
  @include size-max(mobile) {
    &__text {
      flex-direction: column;
      align-items: flex-start;
    }
    &__date {
      margin-top: 0.5rem;
    }
    &__share {
      display: block;
      margin-top: 1rem;
    }
    &__social-sites {
      display: block;
      margin-top: 0.5rem;
    }
    .match-results {
      border-bottom: none;
    }
  }
}

.embed-dialog {
  &__info {
    margin-bottom: 8px;
    &__first {
      margin-top: 0px;
    }
  }

  &__button {
    margin-top: 5px;
  }

  &__textarea {
    width: 100%;
    resize: none;
  }
}

.video-detail-modal {
  background-color: rgba(0,0,0,0.4);
}
