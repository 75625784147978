.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0 0.5rem 0 0;
    display: inline-block;
    vertical-align: middle;
    &.visible-xs {
      display: none !important;
      @include size-max(mobile) {
        display: inline-block !important;
      }
    }
    @include size-max(mobile) {
      margin-bottom: 1rem;
    }
  }
  &--channel-results{
    h3{
      @include size-max(mobile) {
        margin-bottom: 0;
      }
    }
  }
  &__title-sm {
    font-size: 1rem;
    margin-bottom: 0.8rem;
    color: $secondary_action_c;
    text-transform: uppercase;
    @include primary-bold();
  }
  &__link {
    float: right;
    .select {
      width: 12rem;
    }
  }
  &__hidden-label{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;
    @include primary-bold-italic();
    font-size: 1.8rem;
    text-transform: uppercase;
    @include size-max(tablet) {
      font-size: 1.5rem;
    }
  }
}