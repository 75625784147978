.pagination {
  text-align: center;
  overflow: auto;
  button {
    background-color: $secondary_action_c !important;
    @include size(30px);
    min-width: 30px;
    margin: 0 1rem !important;
    padding: 0 !important;
    @include border-radius($radius: 50%);
    color: $text_inverse_c !important;
    @include transition((background-color, color));
    &:disabled {
      background-color: $background_secondary_c !important;
      color: $text_c !important;
    }
    &:hover {
      &:not(:disabled) {
        background-color: $secondary_hover_c  !important;
      }
    }
  }
  .el-pager {
    >.number {
      @include border-radius($radius: 50%);
      background-color: $background_secondary_c !important;
      min-width: 30px;
      margin: 0 0.2rem;
      @include transition((color));
    }
  }
}