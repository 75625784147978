.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $modal_background_c;
  opacity: 0;
  top: 0;
  z-index: -1;
  font-size: 0;
  transition: opacity 0.5s ease 0.3s,
  z-index 0s ease 0.8s;
  &--open {
    opacity: 1;
    z-index: 20;
    @include transition((z-index, opacity));
    transition: opacity 0.5s ease 0s,
    z-index 0s ease 0s;
    &:hover {
      cursor: pointer;
    }
  }
  &:focus {
    outline: none;
  }
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  &__content-wrapper {
    display: inline-block;
    transform: translateY(-20%);
    opacity: 0;
    @include transition((transform, opacity), 0.3s, 0s, ease);
    position: absolute;
    width: $menu_w;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $background_c;
    &--active {
      opacity: 1;
      transform: translateY(0%);
      @include transition((transform, opacity), 0.3s, 0.4s, ease);
      overflow: auto;
      &:hover {
        cursor: auto;
      }
    }
    &:focus {
      outline: none;
    }
    @include size-max(laptop) {
      width: $menu_md_w;
    }
    @include size-max(mobile) {
      width: $menu_xs_w;
    }
  }
  &__content {
    padding: 0 3rem 3rem 3rem;
  }
  &__block {
    padding-top: 3rem;
    &--apps {
      display: flex;
      > a {
        padding-right: 1rem;
        &:last-child {
          padding-right: 0;
          margin-right: 0;
        }
      }
      @include size-max(mobile) {
        padding: 1rem 1.8rem !important;
        justify-content: center;
        > a {
          padding-right: 0.5rem;
        }
      }
    }
  }
  &__list {
    @include reset-list();
    &--color {
      color: $primary_action_c !important;
    }
    > li {
      padding: 0.5rem 0;
      > a {
        font-size: 1.7rem;
        color: $headline_c;
      }
      &:hover {
        a {
          color: $secondary_action_c;
        }
      }
    }

  }
  &__header {
    padding: 1.2rem;
    position: relative;
    > i {
      font-size: 2rem;
      float: right;
      @include transition((color));
    }
    &:hover {
      color: $secondary_action_c;
      cursor: pointer;
    }
    @include size-max(mobile) {
      > i {
        color: #000000;
        display: inline-block;
        @include absolute-center($direction: vertical);
        right: 1rem;
      }
    }
  }
  &__title {
    color: $primary_action_c;
    font-size: 1rem;
    @include secondary-bold();
    &--secondary {
      color: $secondary_action_c;
    }
  }
  @include size-max(mobile) {
    &__content {
      padding: 2rem 0;
    }
    &__logo {
      max-height: 1.8rem;
      height: auto;
      width: auto;
      display: inline-block;
    }
    &__header {
      border-bottom: 1px solid $dividing_c;
    }
    &__itemxs {
      font-size: 1.4rem !important;
      color: $headline_c;
      display: block;
      padding: 1rem 1.8rem !important;
      @include secondary-regular();
    }
    &__block {
      padding: 0;
    }
    &__collapse {
      border-top: none;
      .el-collapse-item__header {
        @extend .menu__itemxs;
        line-height: 2rem;
        height: auto;
        > i {
          line-height: 2rem;
        }
        &.is-active {
          border-bottom: none;
        }
      }
      .el-collapse-item__content {
        padding: 0rem 2rem 1rem 2rem;
        li {
          > a {
            font-size: 1rem;
            color: $headline_c;
            @include secondary-regular();
          }
        }
      }
      .el-collapse-item__arrow {
        margin: 0;
      }
    }
    &__button {
      margin-bottom: 2rem;
    }
    &__list {
      > li {
        &.menu__main {
          padding: 0;
        }
      }
    }
  }
}