.article {
  p {
    color: $text_c;
  }
  h6 {
    font-size: 1.8rem;
    color: $headline_c;
    @include secondary-bold();
    line-height: 1.6;
    margin: 0.5rem 0 2rem;
  }
  img, video, iframe {
    margin: 1.8rem -6.7% 1rem;
    width: calc(100% + 13.4%);
  }
  iframe {
    min-height: 30rem;
  }
  .filer_image_info {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1;
    .title {
      font-size: 1rem;
      @include secondary-regular();
      color: $text_c;
      line-height: 1;
    }
  }
  blockquote, q, blockquote>p, q>p {
    margin: 1rem 6.7%;
    font-size: 1.8rem;
    color: $secondary_action_c;
    line-height: 1.6;
    @include primary-bold-italic();
  }
  li {
    @include secondary-regular();
      font-size: 1.2rem;
      color: $text_c;
      line-height: 2rem;
    >a {
      color: $secondary_action_c;

      &:hover {
        color: $secondary_hover_c;
      }
    }
  }
  ul, ol {
    >li {
      @include secondary-regular();
      font-size: 1.2rem;
      color: $text_c;
      line-height: 2rem;
      >a {
        color: $secondary_action_c;
        &:hover {
          color: $secondary_hover_c;
        }
      }
    }
  }
  @include size-max(tablet) {
    img, video, iframe {
      margin: 1rem 0 1rem;
      width: 100%;
    }
    h6 {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
  @include size-max(mobile) {
    iframe {
      min-height: 12rem;
    }
    p, h6, blockquote>p, q>p {
      font-size: 1.2rem;
      line-height: 1.5;
    }
    blockquote, q {
      >p {
        margin: 0;
      }
    }
  }
}