.breadcrumbs {
  @include reset-list();
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  padding-top: 2rem;
  flex-wrap: wrap;
  z-index: 1;
  >li {
    @include reset-list();
    padding: 0 1rem;
    @include fa-icon();
    position: relative;
    >a {
      color: $text_inverse_c;
      font-size: 0.8rem;
    }
    @include before() {
      @extend .fa;
      content: fa-content($fa-var-caret-right);
      color: $text_inverse_c;
      right: 0;
      bottom: 0;
      font-size: 0.8rem;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    &:hover {
      >a {
        color: $secondary_action_c;
      }
    }
  }
  &--dark {
    >li {
      >a {
        color: $text_c;
      }
      &:before {
        color: $text_c;
      }
    }
  }
  @include size-max(mobile) {
    justify-content: center;
    width: 100%;
    &--xs-dark {
      >li {
        >a {
          color: $text_c;
        }
        &:before {
          color: $text_c;
        }
      }
    }
  }
}