.channel-filter-wrapper {
  @include size-max(mobile) {
    opacity: 0;
    height: 0;
    padding: 0;
    @include transition((opacity, height, margin-top));
    &--open {
      opacity: 1;
      height: 4.5*$main_filter_xs_h;
      margin-top: 1rem;
    }
  }
}