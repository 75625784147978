.channel-header {
  .title {
    padding: 8rem 0 7rem;
    font-style: italic;
    font-weight: 400;
    @include primary-regular();
    >b, >strong {
      color: $headline_c;
      @include primary-bold-italic();
    }
    @include size-max(mobile) {
      padding: 6rem 0 2rem;
      font-size: 2.5rem;
    }
  }
}