.vue-form-generator{
     .form-control{
         height: auto;
     }
}

.form-error {
  input {
    border-color: $primary_action_c !important;
  }
}

.form-error-phone {
  >input, >div {
    border-color: $primary_action_c !important;
  }
}

.errors.help-block, .form-error-message {
  color: $primary_action_c;
  @include secondary-bold();
  font-size: 0.9rem;
  margin-top: 1rem;
  display: block;
}

.form-item {
  width: 100%;
  padding: 0.5rem 0;
}

.file-input-plus {
  margin-right: 0.5rem;
  border: 1px dashed $dividing_c;
  border-radius: $border_radius_primary;
  color: transparent;
  min-width: $file_plus_button_h;
  @include size($file_plus_button_h);
  @include transition((border-color));
  position: relative;
  background-position: center;
  background-size: cover;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &--filled {
    &:before{
      content: none!important;
    }
  }
  &:before {
    content: "\F030";
    display: inline-block;
    color: $text_c;
    font-size: 1.5rem;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    @include absolute-center();
  }
  &:hover {
    border-color: $secondary_action_c !important;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.el-tooltip__popper.is-dark {
  max-width: 14rem;
}

.country-option {
    display: flex;
    align-items: center;
    img {
        max-width: 1rem;
        width: auto;
        max-height: 1rem;
        height: auto;
    }
    p {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
    }
    i, img {
        margin-right: 0.5rem;
    }
}

.new-registration {
    display: flex;
    align-items: center;
    &__form {
    .el-input__inner, .el-textarea__inner {
      color: $text_c;
      border-radius: 8px;
      height: 50px;
      border: solid 1px rgba(0, 0, 0, 0.3);
      @include placeholder() {
        color: $text_c;
      }
    }
    .input-with-select {
      .el-select {
        min-width: 8rem;
      }
      .el-input__inner{
        border-radius: 0 8px 8px 0;
        border-left: 0;
      }
      .el-input-group__prepend {
        background-color: $background_c;
        border-radius: 8px 0 0 8px;
        border: solid 1px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.autojoin-checkbox {
    font-weight: 400;
    white-space: normal;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.field-image {
	.wrapper {
		width: 100%;
	}

	.preview {
		position: relative;
		margin-top: 5px;
		height: 100px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		border: 1px solid #ccc;
		border-radius: 3px;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

		.remove {
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAXUlEQVR42u2SwQoAIAhD88vVLy8KBlaS0i1oJwP3piGVg0Skmpq8HjqZrWl9uwCbGAmwKYGZs/6iqgMyAdJuM8W2QmYKpLt/0AG9ASCv/oAnANd3AEjmAlFT1BypAV+PnRH5YehvAAAAAElFTkSuQmCC");
			width: 16px;
			height: 16px;

			font-size: 1.2em;

			position: absolute;
			right: 0.2em;
			bottom: 0.2em;
			opacity: 0.7;

			&:hover {
				opacity: 1;
				cursor: pointer;
			}
		}
	}
}

.profile-img{
  border-radius: 50%;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1)
}