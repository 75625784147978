
.country-option {
    img {
        max-width: 15px;
    }

    p {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
    }
}
