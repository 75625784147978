.substep {
  @include border();
  border-radius: $border_radius_primary;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
  &__number {
    width: 15%;
    height: 100%;
    background-color: $primary_action_c;

    @include primary-bold-italic();

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    >span {
      @include absolute-center();
      color: $text_inverse_c;
      font-size: 1.2rem;
      text-align: center;
    }
  }
  &__content {
    padding: 1.2rem 1.8rem;
    margin-left: 15%;
  }
  &__title {
    @include secondary-bold();
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: $headline_c;
  }
  &__description {
    @include secondary-regular();
    color: $text_c;
    font-size: 0.9rem;
  }
  &__app {
    max-width: 45%;
    display: inline-block;
    >img {
      width: 100%;
    }
  }
  &--grey {
    margin: 2.5rem 0;
    .substep__number {
      background-color: $dividing_c;
    }
  }
}