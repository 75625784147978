.overlay {
  @include fill-parent(-1);
  position: fixed;
  opacity: 0;
  background-color: $modal_background_c;
  @include transition((opacity));
  &:focus {
    outline: none;
  }
  &__content {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin-top: 15vh;
    iframe {
      min-height: 60vh;
    }

    video {
      width: 100%;
    }
  }
  &--open {
    opacity: 1;
    z-index: 20;
    &:hover {
      cursor: pointer;
    }
  }
  &__close {
    margin-top: -2rem;
    margin-right: -2rem;
    float: right;
    > i {
      color: $text_inverse_c;
      font-size: 2.5rem;
      @include transition((color));
    }
    &:hover {
      cursor: pointer;
      > i {
        color: $secondary_action_c;
      }
    }
    @include size-max(tablet) {
      margin-right: 0;
    }
  }
  &__video {
    height: auto;
    width: 100%;
    &:focus {
      outline: none;
    }
  &__iframe {
    min-height: 60vh !important;
    width: 100%;
  }
  }
  .container {
    outline: none;
    &:focus {
      outline: none;
    }
  }
}

.watermark{
  margin-top: 1%;
  margin-right: 1%;
  position: absolute;
  z-index: 2;
  right: 0;
  max-width: 20%;
}