@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,700,700i|Roboto:400,500,700');

@mixin primary-regular() {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
}

@mixin primary-bold() {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}

@mixin primary-italic() {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-style: italic;
}

@mixin primary-bold-italic() {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-style: italic;
}

@mixin secondary-regular() {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

@mixin secondary-medium() {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

@mixin secondary-bold() {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}