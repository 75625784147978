/*
-------shortcut-legend---------
c-color
h-height
w-weight
lh-line height
cs-container size (width and height is same, may be used for ln if same)
bi-background image
fs-font size
*/

/* GLOBAL */
$primary_action_c: #fa1f63;
$secondary_action_c: #1ed360;
$primary_hover_c: #de1a57;
$secondary_hover_c: #12b85a;
$headline_c: #000000;
$headline_inverse_c: #ffffff;
$text_c: #5c5c5c;
$text_inverse_c: #ffffff;
$dividing_c: #c0c0c0;
$background_c: #ffffff;
$background_secondary_c: #f9f9f9;
$background_inverse_c: #000000;
$form_background_c: #ffffff;
$form_background_secondary_c: #dcdcdc;
$modal_background_c: rgba(0, 0, 0, 0.8);
$shadow_c: rgba(118, 118, 118, 0.08);
$shadow_secondary_c: rgba(0, 0, 0, 0.12);
$overlay_c: rgba(0, 0, 0, 0.4);
$disabled_c: #e0e0e0;

$positive_c: #2bd39d;
$facebook_c: #3b5998;
$facebook_dark_c: #22448a;
$google_c: #d62d20 ;
$google_dark_c: #b52419;
$twitter_c: #1da1f2;
$whatsapp_c: #168E64;
$messenger_c: #006AFF;
$player_action_c: #f98f31;
$player_special_c: #3c97f9;

$base_fs: 14px;
$trans_speed: 0.3s;
$border_radius_primary: 4px;

$z-indexes: (
    "menu--open",
    "modal",
    "navbar",
    "video-slider__arrow",
);

/*CUSTOM*/
$menu_w: 30%;
$menu_md_w: 50%;
$menu_xs_w: 100%;

$circle_with_image_w: 216px;
$circle_with_image_md_w: 200px;
$circle_with_image_sm_w: 150px;

$list_sports_circle_w: 124px;
$list_sports_circle_md_w: 110px;

$newsletter_checkbox_w: 1rem;
$text_select_w: 16rem;

/* BUTTONS */
$button_height_h: 48px;
$button_medium_height_h: 40px;
$button_small_height_h: 32px;
$button_radius: 8px;

/* VIDEO SLIDER */
$slider_button_background_c: $background_c;
$slider_button_c: $secondary_action_c;
$slider_button_w: 40px;

/*BLOG*/
$blog_post_tile_small_h: 284px;
$blog_post_tile_small_image_h: 192px;
$blog_post_tile_xs_h: 270px;

/* FONT AWESOME */
$fa-font-size-base: $base_fs !default;

/* BREAKPOINTS */
$break_min: 320px;
$break_xs_sm: 768px;
$break_sm_md: 992px;
$break_md_lg: 1200px;
$break_lg: 1440px;
$break_lg_xlg: 1920px;

/* FILTER */
$main_filter_h: 72px;
$main_filter_button_h: 48px;
$main_filter_xs_h: 56px;
$main_filter_navbar_h: 40px;
$radio_input_h: 18px;
$radio_input_after_h: 12px;

/* CHANNEL */
$chanel_item_h: 88px;

/*SUBSCRIPTION*/
$input_h: 48px;
$file_plus_button_h: $input_h;

/*VIDEO PLAYER*/
$player_navigation_w: 50px;
$player_item_background_c: #efefef;
$player_item_background_hover_c: #e0e0e0;
$player_type_h: 24px;
$player_header_logo_h: 30px;
