.side-filter {
  height: 100%;
  padding-top: 4rem;
  overflow: hidden;
  &__fixed {
    height: 100vh;
    position: relative;
    overflow: auto;

  }
  &__title {
    font-size: 1rem;
    @include secondary-bold();
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: $headline_c;
  }
  &__subtitle {
    font-size: 1.2rem;
    color: $text_c;
    margin-bottom: 1rem;
    @include secondary-bold();
  }
  &__group {
    margin-bottom: 0.5rem;
    &.open {
      margin-bottom: 2.5rem;
    }
  }
  &__radio {
    display: block;
    margin-left: 0 !important;
    padding: 0.5rem 0;
    .el-radio__label {
      color: $headline_c;
      @include secondary-regular();
      font-size: 1.2rem;
      >i, >img {
        margin-right: 0.5rem;
        max-height: 1rem;
        width: auto;
      }
    }
    .el-radio__inner {
      @include size($radio_input_h);
      background-color: $background_c;
      &:after {
        @include size($radio_input_after_h);
        background-color: $secondary_action_c;
      }
    }
    &.is-checked {
      .el-radio__label {
        color: $headline_c;
      }
      .el-radio__inner {
        @include size($radio_input_h);
        background-color: $background_c;
      }
    }
  }
  &__icon {
    @include size(16px);
    border: 1px solid $dividing_c;
    border-radius: 50%;
    position: relative;
    margin-right: 2rem;
    >i {
      @include absolute-center();
      color: $dividing_c;
      font-size: 10px;
      opacity: 0;
      @include transition((opacity));
      &.show {
        opacity: 1;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__more {
    @include reset-button();
    display: block;
    padding: 0.5rem 0;
  }
}