.post-detail {
  @include border-radius();
  &__header {
    padding: 7rem 0;
    @include bg();
    text-align: left;
    background-color: $background_secondary_c;
    h1 {
      color: $headline_c;
      @include primary-bold-italic();
      margin: 1.8rem 0;
    }
  }
  &__date, &__subtitle {
    font-size: 1rem;
    color: $headline_c;
    @include secondary-regular();
  }
  &__image-wrapper {
    text-align: center;
  }
  &__image {
    max-width: 100%;
    width: auto;
    height: auto;
    @include border-radius();
    margin-top: -10rem;
    margin-bottom: 4rem;
    box-shadow: 0 5px 20px 0 $shadow_secondary_c;
  }
  @include size-max(tablet) {
    &__header {
      padding: 10rem 0 4rem;
    }
  }
  @include size-max(mobile) {
    &__header {
      padding: 0 0 4rem;
    }
    &__date {
      margin-top: 3rem;
    }
    h1 {
      font-size: 2.5rem;
    }
  }
}