.header-multicam {
  @media (min-width: $break_md_lg){
        //compensate negative margin of video player
        top: -7rem;
      }
      @media (min-width: 1560px){
        //compensate negative margin of video player
        top: -11rem;
      }
}
.header-private {
  @media (min-width: $break_md_lg){
        //compensate negative margin of video player
        top: -4rem !important;
      }
      @media (min-width: 1560px){
        //compensate negative margin of video player
        top: -7rem !important;
      }
}
.header-banner {
  @include bg();
  &--channel {
    position: relative;
    @media (min-width: $break_md_lg) {
      height: 300px;
      >.container{
        height: 100%;
      }
    }
  }
  &__click-area{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &__row {
    display: flex;
    align-items: center;
    padding: 14rem 0 17rem;
    @media (min-width: $break_xs_sm){
      text-shadow: 1px 1px 2px black;
    }
    &--channel {
      padding: 3rem 0 10rem;
      @media (min-width: $break_md_lg){
        padding: 0;
        align-items: flex-end;
        height: calc(100% - 80px);
        position: relative;
        //compensate negative margin of video player
        top: -6rem;
      }
      @media (min-width: 1560px){
        padding: 0;
        align-items: flex-end;
        height: calc(100% - 32px);
        position: relative;
        //compensate negative margin of video player
        top: -9rem;
      }
    }
  }
  &__title {
    color: $headline_inverse_c;
    line-height: 3.5rem;
    margin-bottom: 1.8rem;
    @include primary-bold-italic();
  }
  &__description {
    >p {
      color: $text_inverse_c;
      font-size: 1rem;
      line-height: 1.8rem;
    }
  }
  &__buttons {
    padding-top: 1.8rem;
    >.button {
      margin-right: 0.5rem;
    }
  }
  &__tagline {
    color: $secondary_action_c;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    @include primary-bold();
  }
  &__videolink {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $text_inverse_c;
    @include transition((color));
    >span {
      @include primary-bold-italic();
      text-transform: uppercase;
      font-size: 1.4rem;
    }
    >i {
      font-size: 4rem;
      padding-left: 1rem;
    }
    &:hover {
      color: $secondary_action_c;
      cursor: pointer;
    }
  }
  @include size-max(tablet) {
    &__row {
      padding: 10rem 0 14rem;
      &--channel {
        padding: 7rem 0 9rem;
      }
    }
  }
  @include size-max(mobile) {
    &__click-area{
      display: none;
    }
    &__row {
      padding: 2.5rem 0 3.5rem;
      &--channel {
        padding: 2.5rem 0;
      }
    }
    &__title {
      font-size: 2.4rem;
      line-height: 1.25;
      text-align: center;
    }
    &__tagline {
      text-align: center;
    }
    &__videolink {
      flex-wrap: wrap;
      text-align: center;
      flex-direction: column-reverse;
      >i {
        font-size: 3rem;
        margin-bottom: 1rem;
      }
      >span {
        font-size: 1.2rem;
      }
    }
    &__buttons {
      text-align: center;
      >.button {
        height: $button_medium_height_h;
        line-height: $button_medium_height_h - 2;
        padding: 0 1.5rem;
      }
    }
    &--channel {
      background-image: none !important;
      h1 {
        font-size: 2.5rem;
        color: $headline_c;
        text-align: center;
        margin: 0;
      }
      .channel-detail-header__logo {
        @include max-size(5.2rem);
        text-align: center;
      }
      &__row {
        padding: 2.5rem 0;
      }
      @include size-max(tablet) {
        h1 {
          line-height: 3rem;
          margin: 1.5rem 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.channel-detail-header__logo {
    @include max-size(100%, 140px);
    text-align: center;
}

.channel-detail-header__logo-wrapper {
  text-align: center;
  z-index: 1;
}

.channel-detail-header__title {
  z-index: 1;
  h1{
    margin-bottom: 0.3rem !important;
  }
  h5{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-height: 3rem;
    max-width: 75%;
    margin-bottom: 0.3rem !important;
  }
}

.private-channel-warning {
  text-shadow: none;
}