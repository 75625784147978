@for $i from 2 through 6 {
  h#{7 - $i} {
    @include primary-bold-italic();
    color: $headline_c;
    text-transform: uppercase;
    margin: 0 0 1.2rem;
  }
}

h1 {
  font-size: 2.8rem;
  line-height: 3rem;
  @include primary-regular();
  font-style: italic;
  >b, >strong {
    @include primary-bold-italic();
  }
  >span {
    color: $secondary_action_c;
    @include primary-bold-italic();
  }
}

.title {
  font-size: 4rem;
  line-height: 4rem;
  @include size-max(tablet) {
    font-size: 3.8rem;
  }
  @include size-max(mobile) {
    font-size: 2.8rem;
    line-height: 3rem;
  }
}

h2 {
  font-size: 2.2rem;
  line-height: 3rem;
  >b, >strong, >span {
    color: $secondary_action_c;
  }
  @include size-max(mobile) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

h3 {
  font-size: 1.8rem;
  line-height: 2rem;
  @include size-max(tablet) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  @include size-max(mobile) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

h4 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h5 {
  font-size: 1.2rem;
  line-height: 2rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

a {
  @include transition((color, background-color));
  text-decoration: none;
}

p {
  font-size: 1.2rem;
  line-height: 2rem;
  color: $text_c;
  @include secondary-regular();
  >b, >strong {
    @include secondary-bold();
  }
  >a {
    color: $secondary_action_c;
    text-decoration: underline;
    &:hover {
      color: $secondary_hover_c;
    }
  }
  @include size-max(mobile) {
    font-size: 1rem;
    line-height: 1.8rem;
  }
}

button {
  &:focus {
    outline: none;
  }
}

html{
  margin-top: 0 !important;
}