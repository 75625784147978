
.vjs-ad-playing {
  .vjs-control-bar {
    display: none;
  }
}

.vast-blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--hidden {
    display: none;
  }
}

.progress-container {
  margin-top: 5px;
}

@media (min-width: 767px) {
  .progress-container {
    margin-left: 9%;
  }
}

.watermark-container {
  position: relative;
}

@media (max-width: 767px) {
  .watermark-container {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.el-tabs__header {
  margin-bottom: 1px !important;
  border: none !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
  background-color: #ffffff;
}

.el-tabs__content {
  box-shadow: 0 16px 32px 0 rgb(118 118 118 / 8%);
}

.el-tabs__item.is-active {
  filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.5));
}

.el-tabs__item:not(.is-active) div img {
  filter: brightness(0) saturate(100%) invert(91%) sepia(7%) saturate(9%) hue-rotate(354deg) brightness(83%) contrast(88%);
}

.el-tabs__item:not(:first-child) {
  margin-left: 6px;

  .multicam-item {
    margin-left: -1px;
    width: 120px;
  }
}

    .el-tabs__nav {
      border: none !important;
      z-index: auto!important;
    }

.board-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.score-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}

.board-score {
  padding: 2px;
  height: 15px;
  background: white;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
}

.scoreboard-flag {
  width: 15%;
  height: 3px;
}

.board-time {
  width: 40%;
  height: 11px;
  background: #C0C0C0;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.2em;
  color: #000000;
  text-align: center;
}

@media (max-width: 767px) {
  .video-player-live {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 767px) {
  .el-tabs__content {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 767px) {
  .info-overlay__banner {
    width: 320px !important;
    height: 40px !important;
    background-size: contain !important;
  }
}

.mr-negat-20 {
  margin-right: -20px;
}

.el-tabs__item:not(.is-active) .cam-label {
  background-color: grey;
}

.cam-label {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: white;
}

.el-tabs__content {
  background-color: white;
}

.cam-tab-link {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
