.subscription {
  &__header {
    text-align: center;
    padding: 8rem 0 6rem;
  }
  &__description {
    >b, >strong {
      color: $primary_action_c;
    }
  }
  &__row-apps {
    text-align: center;
    div{
      display: block;
      margin: 0 auto;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.8;
    }
    h6 {
      margin: 1.5rem 0 1rem;
      >i {
        color: $primary_action_c;
        margin-right: 0.5rem;
      }
    }
  }
  &__app {
    display: inline-block;
    max-width: 45%;
    padding: 0 0.5rem;
    >img {
      width: 100%;
    }
  }
  &__benefits {
    @include reset-list();
    margin-top: 1.5rem;
    >li {
      color: $text_c;
      @include reset-list();
      font-size: 1.2rem;
      line-height: 1.2;
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      a {
        color: $primary_action_c;
        @include secondary-bold();
      }
      i {
        color: $primary_action_c;
        margin-right: 1rem;
      }
    }
  }
  @include size-max(tablet) {
    &__header {
      padding: 3rem 0 0;
    }
  }
  @include size-max(mobile) {
    &__header {
      padding: 2.5rem 0 0;
    }
  }
}

.subscription-footer {
  display: block;
  @include border-top();
  &__content {
    padding: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        font-size: 0.9rem;
        color: hsl(215deg, 18%, 34%);
        padding-right: 12px;
        padding-left: 12px;

        &:hover {
            text-decoration: underline !important;
        }
    }
  }
  &__title {
    @include primary-italic();
    color: $text_c;
    font-size: 1.5rem;
    >b {
      @include primary-bold-italic();
    }
  }
  &__links {
    @include reset-list();
    >li {
      @include reset-list();
      display: inline-block;
      margin-left: 3rem;
    }
  }
  @include size-max(mobile) {
    text-align: center;
    &__content {
      padding: 0;
      flex-direction: column;
      height: auto;
    }
    padding: 1.5rem 1rem;
    &__links {
      margin-top: 1rem;
      >li {
        margin-left: 0;
        padding: 0.5rem;
      }
    }
  }
}

