.list-sports {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    &__item {
        h6 {
            @include secondary-bold();
            text-align: center;
            font-style: unset;
            color: $text_c;
            @include transition((color));
        }
        &:hover {
            h6, i {
                color: $secondary_action_c;
            }
        }
    }
    &__circle {
        @include size($list_sports_circle_w);
        background-color: $background_secondary_c;
        border-radius: 50%;
        position: relative;
        margin-bottom: 1rem;
        > i {
            @include absolute-center();
            font-size: 4rem;
            color: $text_c;
            @include transition((color));
        }
        @include size-max(laptop) {
            @include size($list_sports_circle_md_w);
            > i {
                font-size: 3rem;
            }
        }
    }
    @include size-max(tablet) {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        &__item {
            padding-right: 1rem;
        }
    }
}

.list-sports-header {
    @include gutter();
    @include size-max(mobile) {
        h3 {
            font-size: 1.2rem;
        }
    }
}