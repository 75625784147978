.video-control {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  &__result {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 4.5rem;
      color: $headline_inverse_c;
      margin: 0 1rem;
      @include primary-bold();
      min-width: 4rem;
      display: inline-block;
    }
    > i {
      font-size: 4.5rem;
      margin: 0 1rem;
      @include size-max(mobile) {
        line-height: unset;
      }
    }
  }
  &__team {
    align-items: center;
    flex: 2;
    padding-top: 3rem;
    &--home {
      justify-content: flex-end;
    }
    &--away {
      justify-content: flex-start;
    }
    img {
      height: 10rem;
      width: auto;
      max-width: 10rem;
      margin-bottom: 0.5rem;
    }
    h4 {
      color: $headline_inverse_c;
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }
  &__navigation-button {
    opacity: 1;
    z-index: 1;
    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: transparent;
      width: $player_navigation_w;
      @include transition((background-image, background-color, color));
      i {
        @include absolute-center();
        font-size: 4rem;
      }
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
      }
    }
    &--inactive {
      opacity: 0;
    }
    &:hover {
      .content {
        color: $secondary_hover_c;
        &--prev {
          @include to-left-gradient(transparent, $overlay_c);
        }
        &--next {
          @include to-right-gradient(transparent, $overlay_c);
        }
      }
    }
  }
  &__play-button {
    @include transition((color));
  }
  &__menu-item {
    &.active {
      color: $secondary_action_c;
    }
  }
  @include size-max(mobile) {
    &__result {
      line-height: unset;
    }
    &__navigation-button {
      .content {
        i {
          font-size: 2rem;
        }
      }
    }
  }
}

.video-club-info {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $overlay_c;
  &:hover {
    .video-control__play-button {
      color: $secondary_action_c;
    }
  }
}

.vjs-control-bar {
  z-index: 2;
}

.video-js  {
    z-index: 1;
    overflow: hidden;
    .vjs-big-play-button {
        display: none;
    }
}