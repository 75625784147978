.error-page {
  @include bg();
  height: calc(100vh - 5rem);
  width: 100%;
  display: block;
  .container {
    height: 100%;
  }
  .row {
    align-items: center;
    height: 100%;
  }
  &--404 {
    height: calc(100vh - 12rem);
  }
  &__content {
    h1 {
      color: $primary_action_c;
      font-size: 5rem;
      @include primary-bold-italic();
    }
    p {
      color: $text_inverse_c;
      margin-top: 2.5rem;
    }
  }
  &__title {
    color: $headline_inverse_c;
    width: 100%;
    font-size: 4rem;
    @include primary-bold-italic();
    text-transform: uppercase;
  }
  &__link {
    margin-top: 2.5rem;
    color: $text_inverse_c;
    @include secondary-bold();
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    >i {
      transform: rotate(180deg);
      display: inline-block;
      margin-right: 0.5rem;
    }
    &:hover {
      color: $secondary_action_c;
    }
  }
  @include size-max(mobile) {
    height: calc(100vh - 3rem);
    text-align: center;
    &__link {
      justify-content: center;
    }
    &__content {
      h1 {
        font-size: 4rem;
      }
    }
    &__title {
      font-size: 3rem;
    }
  }
}

.error-footer {
  .subscription-footer {
    border-top: none;
  }
}